import { PageContent } from "../../../components/PageContent";
import { BudgetOptProjectChart } from "./components/BudgetOptProjectChart";
import { PageCard } from "../../../components/PageCard";
import { useEffect, useState } from "react";
import { ApiService } from "../../../service/ApiService";
import { CircularProgress } from "@mui/material";
import { BudOptItemSelector } from "./filters/BudOptItemSelector.js"
import { SpendoptChartContainer } from "../analytics/components/spendopt/SpendoptChartContainer";
import { TemporalBudgetChart } from "./components/TemporalBudgetChart";
import { GraphRanges } from "./components/BudgetGraphRanges";
import { Box, Grid } from "@mui/material";


export const BudgetOptPage = () => {

  const [loadingOrganizationTree, setLoadingOrganizationTree] = useState(false)
  const [loadingBudoptData, setLoadingBudoptData] = useState(false)
  const [loadingItemTimeseries, setLoadingItemTimeseries] = useState(false)
  const [loadingItemCurve, setLoadingItemCurve] = useState(false)
  const [loadingItemSplitCurves, setLoadingItemSplitCurves] = useState(false)

  const [companyList, setCompanyList] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null)
  const [budgetOptdata, setBudgetOptdata] = useState(null)
  const [itemTimeseries, setItemTimeseries] = useState(null)
  const [itemCurveData, setItemCurveData] = useState(null)
  const [itemSplitCurveData, setItemSplitCurveData] = useState(null)


  const loadItemData = (itemInfo) => {
    if (itemInfo != null) {
      setLoadingItemTimeseries(true)
      setLoadingItemCurve(true)
      //setLoadingItemSplitCurves(true)
      const itemBudgetTimeseries = ApiService.getItemBudgetTimeseries(itemInfo.projectId, itemInfo.itemId)
      const itemcurve = ApiService.getItemCurve(itemInfo.projectId, itemInfo.itemId)
      //const itemSplitCurves = ApiService.getItemSplitCurves(itemInfo.projectId, itemInfo.itemId)
      Promise.all([itemBudgetTimeseries, itemcurve,
        //itemSplitCurves
      ])
        .then((data) => {
          setItemTimeseries(data[0])
          setItemCurveData(data[1])
          if (data[1].target_name === "conversions" || data[1].target_name === "revenue")
            loadItemSplitCurvesData(itemInfo)
          //setItemSplitCurveData(data[2])
          //console.log(data[2])
        })
        .catch(() => {
          console.log("ERRORE nel caricamento dei dati dell'item")
          setItemTimeseries(null)
          setItemCurveData(null)
          //setItemSplitCurveData(null)
        })
        .finally(() => {
          setLoadingItemTimeseries(false)
          setLoadingItemCurve(false)
          //setLoadingItemSplitCurves(false)
        })
    }
  }

  const loadItemSplitCurvesData = (itemInfo) => {
    setLoadingItemSplitCurves(true)
    ApiService.getItemSplitCurves(itemInfo.projectId, itemInfo.itemId)
      .then((data) => {
        setItemSplitCurveData(data)

      })
      .catch(() => {
        setItemSplitCurveData(null)
      })
      .finally(() => {
        setLoadingItemSplitCurves(false)
      })
  }

  //
  const loadProjectData = (projectInfo) => {
    if (projectInfo != null) {
      setLoadingBudoptData(true)
      ApiService.getProjectCurves(projectInfo.projectId)
        .then((data) => {
          setBudgetOptdata(data)
        })
        .catch(() => {
          console.log("ERRORE nel caricamento di budgetOptData")
          setBudgetOptdata(null)
        })
        .finally(() => {
          setLoadingBudoptData(false)
        });
    }
  }

  const loadOrganizationTree = () => {
    setLoadingOrganizationTree(true)
    ApiService.getOrganizationTree()
      .then((companies) => {
        setCompanyList(companies)
      })
      .catch(() => {
        console.log("ERRORE nel caricamento di organiation tree")
        setCompanyList(null)
      })
      .finally(() => {
        setLoadingOrganizationTree(false)
      });
  }

  useEffect(() => {
    loadOrganizationTree()
  }, []);



  return (
    <PageContent>

      {loadingOrganizationTree ? (
        <CircularProgress />
      ) : (
        <>
          <PageCard title="Chart Filters">
            <BudOptItemSelector
              companyList={companyList}
              onItemChange={loadItemData}
              onProjectChange={(projectInfo) => {
                setSelectedProject(projectInfo)
                loadProjectData(projectInfo)
              }}
            />
          </PageCard>
          <PageCard title="Spendopt Charts">
            <SpendoptChartContainer filterData={selectedProject} />
          </PageCard>
          <PageCard title="Project's items graph">
            {(budgetOptdata === null && loadingBudoptData === false) ? ("Selezionare un progetto") : (
              loadingBudoptData ? (
                <CircularProgress />
              ) : (
                <BudgetOptProjectChart
                  budgetOptdata={budgetOptdata}
                />
              )
            )}
          </PageCard>
          <PageCard title="Cost-Target graph">
            {(itemCurveData === null && loadingItemCurve === false) ? ("Selezionare un Item") : (
              loadingItemCurve ? (
                <CircularProgress />
              ) : (
                <GraphRanges
                  xData={itemCurveData.input_data}
                  yLq={itemCurveData.target_data_lq}
                  yMn={itemCurveData.target_data_mn}
                  yUq={itemCurveData.target_data_uq}
                  pointsX={itemCurveData.x_historical_data}
                  pointsY={itemCurveData.y_historical_data}
                  xLabel={itemCurveData.input_name}
                  yLabel={itemCurveData.target_name} />
              )
            )}
          </PageCard>
          {(itemCurveData === null) ? ("") : ( itemCurveData.target_name !== "conversions" && itemCurveData.target_name !== "revenue") ? ("") : (
            <PageCard title="Click-cost graphs">
              {(itemSplitCurveData === null && loadingItemSplitCurves === false) ? ("Selezionare un Item") : (
                loadingItemSplitCurves ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Box p={1.5}>
                      <Grid container>
                        <Grid item xs={6}>

                          <GraphRanges
                            xData={itemSplitCurveData.input_data}
                            yLq={itemSplitCurveData.clicks_data_lq}
                            yMn={itemSplitCurveData.clicks_data_mn}
                            yUq={itemSplitCurveData.clicks_data_uq}
                            pointsX={itemSplitCurveData.input_historical_data}
                            pointsY={itemSplitCurveData.clicks_historical_data}
                            xLabel={itemSplitCurveData.input_name}
                            yLabel={"Clicks"}
                            title={`${itemSplitCurveData.input_name}-Clicks graph`} />
                        </Grid>
                        
                        <Grid item xs={6}>

                          <GraphRanges
                            xData={itemSplitCurveData.input_data}
                            yLq={itemSplitCurveData.target_data_lq}
                            yMn={itemSplitCurveData.target_data_mn}
                            yUq={itemSplitCurveData.target_data_uq}
                            pointsX={itemSplitCurveData.clicks_historical_data}
                            pointsY={itemSplitCurveData.target_historical_data}
                            xLabel={"Clicks"}
                            yLabel={itemSplitCurveData.target_name}
                            title={`Clicks-${itemSplitCurveData.target_name} graph`} />
                        </Grid>

                      </Grid>
                    </Box>
                  </>
                )
              )}
            </PageCard>
          )}
          <PageCard title="Item's budget over time">
            {(itemTimeseries === null && loadingItemTimeseries === false) ? ("Selezionare un Item") : (
              loadingItemTimeseries ? (
                <CircularProgress />
              ) : (
                <TemporalBudgetChart
                  itemTimeseries={itemTimeseries} />
              )
            )}

          </PageCard>
        </>)}

    </PageContent>
  );
};
