import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
  InputLabel,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ADCUBE_ENTITY } from "../../../../common/const/ADcubeEntity";
import { ApiService } from "../../../../service/ApiService";

export const UsersCompany = ({ selectedEntity, companyList }) => {
  const [entityList, setEntityList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const handleSelectedValueChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const updateSelectedEntity = () => {
    if (selectedValue !== null && selectedValue !== "") {
      if (selectedEntity.type === "user") {
        ApiService.addCompanyToUser(
          selectedEntity.user_id,
          selectedValue,
          "guest"
        )
          .then(() => {})
          .catch()
          .finally(() => {});
      } else if (selectedEntity.type === "company") {
        ApiService.addUserToAdvertiser(
          selectedEntity.user_id,
          selectedEntity.company_id,
          selectedValue
        )
          .then(() => {})
          .catch()
          .finally(() => {});
      } else if (selectedEntity.type === "advertiser") {
        ApiService.addUserToProject(
          selectedEntity.user_id,
          selectedEntity.advertiser_id,
          selectedValue,
          -1
        )
          .then(() => {})
          .catch()
          .finally(() => {});
      }
    }
  };

  const getInputLabel = () => {
    let label = "";
    switch (selectedEntity.type) {
      case ADCUBE_ENTITY.USER:
        label = ADCUBE_ENTITY.COMPANY;
        break;
      case ADCUBE_ENTITY.COMPANY:
        label = ADCUBE_ENTITY.ADVERTISER;
        break;
      case ADCUBE_ENTITY.ADVERTISER:
        label = ADCUBE_ENTITY.PROJECT;
        break;
    }
    return label.charAt(0).toUpperCase() + label.slice(1);
  };

  const loadData = () => {
    if (selectedEntity.type === "user") {
      let companies = [];
      for (let company of companyList) {
        companies.push({
          name: company.name,
          id: company.company_id,
          disabled: selectedEntity.company_id !== null ? true : false,
        });
      }
      setDefaultValue(
        selectedEntity.company_id !== null ? selectedEntity.company_id : ""
      );
      setEntityList(companies);
    } else if (selectedEntity.type === "company") {
      let advertisers = [];
      for (let company of companyList) {
        for (let advertiser of company.advertisers) {
          advertisers.push({
            name: advertiser.name,
            id: advertiser.advertiser_id,
            disabled: selectedEntity.advertisers.length > 0 ? true : false,
          });
        }
      }
      setEntityList(advertisers);
    } else if (selectedEntity.type === "advertiser") {
      let projects = [];
      for (let company of companyList) {
        for (let advertiser of company.advertisers) {
          for (let project of advertiser.projects) {
            projects.push({
              name: project.name,
              id: project.project_id,
              disabled: selectedEntity.projects.length > 0 ? true : false,
            });
          }
        }
      }
      setEntityList(projects);
    }
  };

  useEffect(() => {
    loadData();
    setSelectedValue("");
  }, [selectedEntity]);

  return (
    <>
      <Box sx={{ padding: "0 1rem" }}>
        <Box>
          <Paper elevation={3} style={{ marginBottom: "1rem" }}>
            <Grid container>
              <Grid item xs={11}>
                <Box
                  style={{
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Typography>
                    {selectedEntity.type.charAt(0).toUpperCase() +
                      selectedEntity.type.slice(1)}
                    : {selectedEntity.name}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={10}>
                <Box
                  style={{
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <InputLabel>{getInputLabel()}</InputLabel>
                  <Select
                    value={selectedValue}
                    onChange={handleSelectedValueChange}
                    label={getInputLabel()}
                    defaultValue={defaultValue}
                  >
                    {entityList.map((c) => (
                      <MenuItem key={c.id} value={c.id} disabled={c.disabled}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  style={{
                    padding: "1rem",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    display="flex"
                    alignItems="right"
                    variant="contained"
                    onClick={updateSelectedEntity}
                  >
                    CONFERMA
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </>
  );
};
