import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";

const FORM_STYLE = { minWidth: "100px" };

export const UserSelector = ({
  users,
  onUserChange,
  onAdvertiserChange,
  onProjectChange,
}) => {
  const [optionList, setOptionList] = useState({
    users: [],
    advertisers: [],
    projects: [],
  });

  const [selectedValue, setSelectedValue] = useState({
    project: "none",
    advertiser: "none",
    user: "none",
  });

  useEffect(() => {
    setOptionList((old) => ({
      ...old,
      users: users.map((u) => ({
        id: u.user_id,
        name: u.name,
        surname: u.surname,
      })),
    }));
  }, [users]);

  const handleUserChange = (e) => {
    const selectedUser = e.target.value;
    setSelectedValue(() => ({
      user: selectedUser,
      advertiser: "none",
      project: "none",
    }));
    onProjectChange(null)
    onAdvertiserChange(null)
    onUserChange(selectedUser)
    setOptionList((old) =>
      selectedUser !== "none"
        ? {
            ...old,
            advertisers: users
              .find((u) => u.user_id === selectedUser)
              .advertisers.map((a) => ({ id: a.advertiser_id, name: a.name })),
          }
        : {
            ...old,
            advertisers: [],
            projects: [],
          }
    );
  };

  const handleAdvertiserChange = (e) => {
    const selectedAdvertiser = e.target.value;
    setSelectedValue((old) => ({
      ...old,
      advertiser: selectedAdvertiser,
      projects: "none",
    }));
    onProjectChange(null)
    onAdvertiserChange(selectedAdvertiser);
    setOptionList((old) =>
      selectedAdvertiser !== "none"
        ? {
            ...old,
            projects: users
              .find((u) => u.user_id === selectedValue.user)
              .advertisers.find((a) => a.advertiser_id === selectedAdvertiser)
              .projects.map((p) => ({ id: p.project_id, name: p.name })),
          }
        : {
            ...old,
            projects: [],
          }
    );
  };

  const handleProjectChange = (e) => {
    const selectedProject = e.target.value;
    setSelectedValue((old) => ({
      ...old,
      project: selectedProject,
    }));
    onProjectChange(selectedProject);
  };

  return (
    <Box
      style={{ display: "flex", gap: "1rem", alignItems: "center" }}
      component="div"
    >
      <FormControl size="small">
        <InputLabel id="company-label">User</InputLabel>
        <Select
          sx={FORM_STYLE}
          labelId="company-label"
          id="company-select"
          value={selectedValue.company}
          onChange={handleUserChange}
          label="Company"
        >
          <MenuItem value="none">
            <em>-</em>
          </MenuItem>
          {optionList.users.map((u) => (
            <MenuItem key={u.id} value={u.id}>
              {`${u.name} ${u.surname}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {optionList.advertisers.length > 0 ? (
        <FormControl size="small">
          <InputLabel id="advertiser-label">Advertiser</InputLabel>
          <Select
            sx={FORM_STYLE}
            labelId="advertiser-label"
            id="advertiser-select"
            value={selectedValue.advertiser}
            onChange={handleAdvertiserChange}
            label="Advertiser"
          >
            <MenuItem value="none">
              <em>-</em>
            </MenuItem>
            {optionList.advertisers.map((a) => (
              <MenuItem key={a.id} value={a.id}>
                {a.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        ""
      )}
      {optionList.projects.length > 0 ? (
        <FormControl size="small">
          <InputLabel id="project-label">Project</InputLabel>
          <Select
            sx={FORM_STYLE}
            labelId="project-label"
            id="project-select"
            value={selectedValue.project}
            onChange={handleProjectChange}
            label="Projects"
          >
            <MenuItem value="none">
              <em>-</em>
            </MenuItem>
            {optionList.projects.map((p) => (
              <MenuItem key={p.id} value={p.id}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        ""
      )}
    </Box>
  );
};
