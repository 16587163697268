import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { METRICS_TYPE } from "../../../common/const/Metrics";
import { PageCard } from "../../../components/PageCard";
import { PageContent } from "../../../components/PageContent";
import { ApiService } from "../../../service/ApiService";
import { AnalyticsChartContainer } from "./components/analytics/AnalyticsChartContainer";
import { AnalyticChartFilter } from "./components/filters/AnalyticChartFilter";
import { SpendoptChartContainer } from "./components/spendopt/SpendoptChartContainer";

export const AnalyticsPage = () => {
  const [loading, setLoading] = useState(true);
  const [companyList, setCompanyList] = useState([]);

  const [filterData, setFilterData] = useState(null);
  const [metricsData, setMetricsData] = useState([METRICS_TYPE.COST]);

  useEffect(() => {
    setLoading(true);
    ApiService.getOrganizationTree()
      .then((responseCompanyList) => {
        setCompanyList(responseCompanyList);
      })
      .catch(() => {
        setCompanyList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return loading ? (
    <CircularProgress />
  ) : (
    <PageContent>
      <PageCard title="Chart Filters">
        <AnalyticChartFilter
          companyList={companyList}
          onChangeFilter={setFilterData}
          onChangeMetrics={setMetricsData}
        />
      </PageCard>
      <PageCard title="Analytics Charts">
        <AnalyticsChartContainer
          filterData={filterData}
          metricsData={metricsData}
        />
      </PageCard>
      <PageCard title="Spendopt Charts">
        <SpendoptChartContainer filterData={filterData} />
      </PageCard>
    </PageContent>
  );
};
