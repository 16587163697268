import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ApiService } from "../../../../../service/ApiService";
import { JobRootItem } from "./JobRootItem";

export const JobsContainer = ({ dateRangeFilter, jobStatusFilter }) => {
  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    if (dateRangeFilter !== null) {
      setJobs(null);
      ApiService.getJobs(...dateRangeFilter)
        .then((jobList) => {
          jobList.sort((a, b) => b.start_time - a.start_time);
          setJobs(jobList);
        })
        .catch(() => {
          setJobs(null);
        });
    }
  }, [dateRangeFilter]);

  return jobs ? (
    jobs.filter((job) => jobStatusFilter.includes(job.status)).length > 0 ? (
      jobs
        .filter((job) => jobStatusFilter.includes(job.status))
        .map((job) => (
          <Box key={job.key} my={1}>
            <JobRootItem jobRootData={job} />
          </Box>
        ))
    ) : (
      <Box my={1}>
        <Typography>Non ci sono job con i filtri selezionati.</Typography>
      </Box>
    )
  ) : (
    <CircularProgress />
  );
};
