import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ApiService } from "../../../../../service/ApiService";

export const DeleteAdvertiserBox = ({
  advertiserName,
  advertiserId,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const deleteAdvertiser = () => {
    setLoading(true);
    ApiService.deleteAdvertiser(advertiserId)
      .then(() => {
        onDelete();
      })
      .catch((error) => {
        console.log("errore delete advertiser", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button color="error" variant="contained" onClick={() => setOpen(true)}>
        DELETE ADVERTISER
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              align="center"
            >
              ATTENZIONE!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Con questa operazione cancellerai definitivamente l'advertiser{" "}
              <strong>{advertiserName}</strong>. <br />
              Confermi?
            </Typography>
          </Box>
          <Box textAlign="right">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                color="error"
                variant="contained"
                onClick={deleteAdvertiser}
              >
                CONFERMA
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
