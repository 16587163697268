import { ADCubeConfigService } from "./ADCubeConfigService";

const API_HOST = process.env.REACT_APP_API_HOST;

const buildApiURL = (path) => API_HOST + path;
const buildConfig = (config = {}, accessKey = null) => ({
  ...config,
  headers: {
    ...(config.headers ? config.headers : {}),
    Authorization:
      "Admin " + (accessKey ? accessKey : ADCubeConfigService.getAccessKey()),
  },
});

const login = (accesKey) =>
  fetch(buildApiURL("/check-auth/"), buildConfig({}, accesKey));

const getOrganizationTree = () =>
  fetch(buildApiURL("/organization-tree/"), buildConfig()).then((response) =>
    response.json()
  );

  const getUsers = () =>
  fetch(buildApiURL("/users/"), buildConfig()).then((response) =>
    response.json()
  );

const getEvents = (from_date, to_date, project_id) =>
  fetch(
    buildApiURL(`/events/project?from_date=${from_date}&to_date=${to_date}&project_id=${project_id}`),
    buildConfig()
  ).then((response) => response.json());

const getProjectCurves = (project_id) =>
  fetch(
    buildApiURL(`/budopt/project?project_id=${project_id}`),
    buildConfig()
  ).then((response) => response.json());

const getItemCurve = (project_id, item_id) =>
  fetch(
    buildApiURL(`/budopt/project/item/curve?project_id=${project_id}&item_id=${item_id}`),
    buildConfig()
  ).then((response) => response.json());

const getItemSplitCurves = (project_id, item_id) =>
  fetch(
    buildApiURL(`/budopt/project/item/splitcurve?project_id=${project_id}&item_id=${item_id}`),
    buildConfig()
  ).then((response) => response.json());

const getItemBudgetTimeseries = (project_id, item_id) =>
  fetch(
    buildApiURL(`/budopt/project/item?project_id=${project_id}&item_id=${item_id}`),
    buildConfig()
  ).then((response) => response.json());

const getUsersByProject = (companyId, advertiserId, projectId) =>
  fetch(buildApiURL(`/users/by-project/?company_id=${companyId}&advertiser_id=${advertiserId}&project_id=${projectId}`), buildConfig()).then(
    (response) => response.json()
  );

const getUsersByAdvertiser = (companyId, advertiserId) =>
  fetch(
    buildApiURL(`/users/by-advertiser/?company_id=${companyId}&advertiser_id=${advertiserId}`),
    buildConfig()
  ).then((response) => response.json());

const getUsersByCompany = (id) =>
  fetch(buildApiURL(`/users/by-company/?company_id=${id}`), buildConfig()).then(
    (response) => response.json()
  );

const updateUserProjectPermissionCode = (userId, projectId, permissionCode) =>
  fetch(
    buildApiURL("/user/permissions/project/"),
    buildConfig({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        project_id: projectId,
        permissions: permissionCode,
      }),
    })
  );

  const addUserToProject = (userId, advertiserId, projectId, permissionCode) =>
  fetch(
    buildApiURL("/user/permissions/project/"),
    buildConfig({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        advertiser_id: advertiserId,
        project_id: projectId,
        permissions: permissionCode,
      }),
    })
  );

const addCompanyToUser = (userId, companyId, role) =>
  fetch(
    buildApiURL("/user/permissions/company/"),
    buildConfig({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        company_id: companyId,
        role,
      }),
    })
  );

const updateUserAdvertiserRole = (userId, advertiserId, role) =>
  fetch(
    buildApiURL("/user/permissions/advertiser/"),
    buildConfig({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        advertiser_id: advertiserId,
        role,
      }),
    })
  );

  const updateUserCompanyRole = (userId, companyId, role) => {
    fetch(
      buildApiURL("/user/permissions/company/"),
      buildConfig({
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          company_id: companyId,
          role
        }),
      })
    ).then((response) => response.json());
  }

  const addUserToAdvertiser = (userId, companyId, advertiserId) =>
  fetch(
    buildApiURL("/user/permissions/advertiser/"),
    buildConfig({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        company_id: companyId,
        advertiser_id: advertiserId,
      }),
    })
  );

const getAnalyticsChartData = (projectId, itemList, startDate, endDate) =>
  fetch(
    buildApiURL("/analytics/graph-data/"),
    buildConfig({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: projectId,
        selected_item_ids: itemList,
        start_date: startDate,
        end_date: endDate,
      }),
    })
  ).then((response) => response.json());

const getJobs = (startDate, endDate) =>
  fetch(buildApiURL(`/jobs/${startDate}/${endDate}`), buildConfig({})).then(
    (response) => response.json()
  );

const getJobTree = (job_root_prefix) =>
  fetch(buildApiURL(`/job-tree/${job_root_prefix}`), buildConfig({})).then(
    (response) => response.json()
  );

const secretServerCheck = () =>
  fetch("https://secrets.mlcube.com/", { mode: "no-cors" });
const pypiServerCheck = () =>
  fetch("https://pypi.mlcube.com/", { mode: "no-cors" });

const addAdvertiserToAdcubeCompany = (advertiserId) =>
  fetch(
    buildApiURL(`/adcube-company/advertiser/add?advertiser_id=${advertiserId}`),
    buildConfig({
      method: "PUT",
    })
  );

const getProjectOverview = (advertiserId, projectId) =>
  fetch(
    buildApiURL(
      `/project/overview/?adv_id=${advertiserId}&project_id=${projectId}`
    ),
    buildConfig({})
  ).then((response) => response.json());

const getProjectSettings = (projectId) =>
  fetch(
    buildApiURL(`/project/settings/?project_id=${projectId}`),
    buildConfig({})
  ).then((response) => response.json());

const getSpendoptData = (
  advertiserId,
  projectId,
  budget,
  optimizationHorizon
  ) =>
  fetch(
    buildApiURL(`/optimizer/spendopt/`),
    buildConfig({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        advertiser_id: advertiserId,
        project_id: projectId,
        budgets: [budget],
        optimization_horizon: optimizationHorizon,
      }),
    })
  ).then((response) => response.json());

const deleteAdvertiser = (advertiserId) =>
  fetch(
    buildApiURL(`/advertiser/${advertiserId}`),
    buildConfig({
      method: "DELETE",
    })
  );

const getNoCompanyAdvertisers = () =>
  fetch(buildApiURL(`/advertiser-no-company`), buildConfig({})).then(
    (response) => response.json()
  );

const deleteNoCompanyAdvertisers = () =>
  fetch(
    buildApiURL(`/advertiser-no-company`),
    buildConfig({
      method: "DELETE",
    })
  );

const getUserActivity = (fromDate, toDate, targetUser, method) =>
  fetch(
    buildApiURL(`/user/activity?from_date=${fromDate}&to_date=${toDate}&target_user=${targetUser}&method=${method}`),
    buildConfig({})
  ).then((response) => response.json());

const getAdvertiserActivity = (fromDate, toDate, targetUser, advertiser, method) =>
  fetch(
    buildApiURL(`/advertiser/activity?from_date=${fromDate}&to_date=${toDate}&target_user=${targetUser}&advertiser=${advertiser}&method=${method}`),
    buildConfig({})
  ).then((response) => response.json());

const getProjectActivity = (fromDate, toDate, targetUser, advertiser, project, method) =>
  fetch(
    buildApiURL(`/project/activity?from_date=${fromDate}&to_date=${toDate}&target_user=${targetUser}&advertiser=${advertiser}&method=${method}&project=${project}`),
    buildConfig({})
  ).then((response) => response.json());

const generateActionableInsights = (advertiserId, projectId) => {
  fetch(
    buildApiURL("/actionable-insights/"),
    buildConfig({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        advertiser_id: advertiserId,
        project_id: projectId,
      }),
    })
  ).then((response) => response.json());
}

const generateCopilotSuggestions= (projectId) => {
  fetch(
    buildApiURL("/creativity-copilot/"),
    buildConfig({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: projectId,
      }),
    })
  ).then((response) => response.json());
}

const updateAdvertiserType = (advertiserId, advertiserType) => {
  fetch(
    buildApiURL("/advertiser/type/"),
    buildConfig({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        advertiser_id: advertiserId,
        advertiser_type: advertiserType,
      }),
    })
  ).then((response) => response.json());
}

const updateProjectTrainingDays = (advertiserId, advertiserType, nTrainingDays) => {
  fetch(
    buildApiURL("/project/training-days/"),
    buildConfig({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        advertiser_id: advertiserId,
        project_id: advertiserType,
        n_training_days: nTrainingDays
      }),
    })
  ).then((response) => response.json());
}

export const ApiService = {
  login,
  getEvents,
  getOrganizationTree,
  getUsersByProject,
  getUsersByAdvertiser,
  getUsersByCompany,
  updateUserProjectPermissionCode,
  addCompanyToUser,
  addUserToAdvertiser,
  addUserToProject,
  updateUserAdvertiserRole,
  getAnalyticsChartData,
  getJobs,
  getUsers,
  getJobTree,
  getProjectCurves,
  getItemCurve,
  getItemSplitCurves,
  getItemBudgetTimeseries,
  getUserActivity,
  secretServerCheck,
  pypiServerCheck,
  addAdvertiserToAdcubeCompany,
  getProjectOverview,
  getProjectSettings,
  getSpendoptData,
  generateActionableInsights,
  deleteAdvertiser,
  getNoCompanyAdvertisers,
  deleteNoCompanyAdvertisers,
  updateAdvertiserType,
  updateProjectTrainingDays,
  updateUserCompanyRole,
  getAdvertiserActivity,
  getProjectActivity,
  generateCopilotSuggestions
};
