import { PageContent } from "../../../components/PageContent";
import { AdvertiserManagement } from "./advertiser-management/AdvertiserManagement";
import { NoCompanyAdvertisers } from "./no-company-advertisers/NoCompanyAdvertisers";

export const AdvertisersPage = () => {
  return (
    <PageContent>
      <AdvertiserManagement />

      <NoCompanyAdvertisers />
    </PageContent>
  );
};
