import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ADcubeLogoLight from "../../../assets/images/adcube-logo-light.png";
import ADcubeLogoDark from "../../../assets/images/adcube-logo-dark.png";
import { ADCubeAdminContext } from "../../../context/ADCubeAdminContext";
import { ADCubeConfigService } from "../../../service/ADCubeConfigService";
import { ApiService } from "../../../service/ApiService";

export const LoginPage = () => {
  let navigate = useNavigate();
  const [accesKey, setAccessKey] = useState("");
  const [accessError, setAccessError] = useState(false);
  const [accessDisabled, setAccessDisabled] = useState(false);

  const adCubeAdminContext = useContext(ADCubeAdminContext);

  const executeLogin = (event) => {
    event.preventDefault();
    setAccessError(false);
    setAccessDisabled(true);
    ApiService.login(accesKey)
      .then(() => {
        ADCubeConfigService.setAccesKey(accesKey);
        adCubeAdminContext.setAuthenticated(true);
        navigate("/");
      })
      .catch(() => {
        setAccessError(true);
      })
      .finally(() => {
        setAccessDisabled(false);
      });
  };

  return (
    <Paper
      elevation={10}
      sx={{
        padding: "6rem",
      }}
    >
      <Box marginBottom={5}>
        <img
          alt="adcube-logo"
          src={adCubeAdminContext.themeLight ? ADcubeLogoLight : ADcubeLogoDark}
          style={{
            width: "16rem",
            padding: "1.5rem 1.5rem  0",
          }}
        />
        <Typography variant="body1" align="center" color="white">
          ADMIN PANEL
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        direction={"column"}
        justify={"center"}
        alignItems={"center"}
        component="form"
        noValidate
        onSubmit={executeLogin}
      >
        <Grid item xs={12}>
          <TextField
            label="Access Key"
            value={accesKey}
            onChange={(e) => setAccessKey(e.currentTarget.value)}
            error={accessError}
            helperText={accessError ? "Invalid key" : ""}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            disabled={accessDisabled}
          >
            ACCESS
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
