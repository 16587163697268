const buildNodeId = (c = null, a = null, p = null, i = null) =>
  [c, a, p, i].join("_");

const filterCompanyNodeList = (nodeList) =>
  nodeList.filter((node) => node.endsWith("___"));
const filterAdvertiserNodeList = (nodeList) =>
  nodeList.filter((node) => node.endsWith("___") || node.endsWith("__"));
const filterProjectNodeList = (nodeList) =>
  nodeList.filter(
    (node) => node.endsWith("___") || node.endsWith("__") || node.endsWith("__")
  );

const buildAllNodeList = (companyList) => {
  const nodeList = [];
  companyList.forEach((c) => {
    nodeList.push(buildNodeId(c.company_id));
    c.advertisers.forEach((a) => {
      nodeList.push(buildNodeId(c.company_id, a.advertiser_id));
      a.projects.forEach((p) => {
        nodeList.push(buildNodeId(c.company_id, a.advertiser_id, p.project_id));
        p.items.forEach((i) => {
          nodeList.push(
            buildNodeId(
              c.company_id,
              a.advertiser_id,
              p.project_id,
              i.item_data.item_id
            )
          );
        });
      });
    });
  });
  return nodeList;
};

const checkCompanyAdvertisers = (companyList) => {
  const adCubeCompany = companyList.find((c) => c.is_adcube_company);
  if (adCubeCompany) {
    const adCubeAdvertisersId = adCubeCompany.advertisers.map(
      (a) => a.advertiser_id
    );

    companyList.forEach((company) => {
      let addWarning = false;
      company.advertisers.forEach((advertiser) => {
        if (!adCubeAdvertisersId.includes(advertiser.advertiser_id)) {
          addWarning = true;
          advertiser.warningNotInAdminCompany = true;
        }
        company.warningAdvertiserNotInAdminCompany = addWarning;
      });
    });
  }
  return companyList;
};

const enrichCompanyListData = (inputCompanyList) => {
  let companyList = [...inputCompanyList];
  companyList = checkCompanyAdvertisers(companyList);
  return companyList;
};

export const OrganizationTreeUtils = {
  buildNodeId,
  buildAllNodeList,
  filterCompanyNodeList,
  filterAdvertiserNodeList,
  filterProjectNodeList,
  enrichCompanyListData,
};
