const enrichCompanyList = (companyList) => {
  const adCubeCompany = companyList.find((c) => c.is_adcube_company);
  if (adCubeCompany) {
    const adCubeAdvertisersId = adCubeCompany.advertisers.map(
      (a) => a.advertiser_id
    );

    companyList.forEach((company) => {
      company.warningAdvertiserNotInAdminCompany = company.advertisers
        .map((a) => a.advertiser_id)
        .some((a) => !adCubeAdvertisersId.includes(a));
    });
  }
  return companyList;
};

export const AdvertisersPageUtils = {
  enrichCompanyList,
};
