import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { JobUtils } from "../../../../../common/utils/JobsUtils";
import { ApiService } from "../../../../../service/ApiService";
import { JobTreeNode } from "./JobTreeNode";

export const JobTree = ({ jobRootPrefix }) => {
  const [loading, setLoading] = useState(true);
  const [jobTree, setJobTree] = useState(null);

  useEffect(() => {
    setLoading(true);
    ApiService.getJobTree(jobRootPrefix)
      .then((jobtTreeNodeList) => {
        setJobTree(JobUtils.buildTree(jobtTreeNodeList));
      })
      .catch(() => {
        setJobTree(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [jobRootPrefix]);

  return loading ? (
    <CircularProgress />
  ) : jobTree ? (
    <Paper style={{ margin: "0 1rem" }}>
      <Box p={1}>
        <JobTreeNode jobNode={jobTree}></JobTreeNode>
      </Box>
    </Paper>
  ) : (
    <Box>
      <Typography>Dati non trovati</Typography>
    </Box>
  );
};
