import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ADcubeLogoDark from "../assets/images/adcube-logo-dark.png";
import { ThemeSwitcher } from "../components/ThemeSwitcher";
import { ADCubeAdminContext } from "../context/ADCubeAdminContext";
import { ADCubeConfigService } from "../service/ADCubeConfigService";

const MENU_ITEM = [
  {
    key: "ORGANIZATION",
    label: "ORGANIZATION",
    url: "/organization",
  },
  {
    key: "USERS",
    label: "USERS",
    url: "/user-permission",
  },
  {
    key: "ADVERTISERS",
    label: "ADVERTISERS",
    url: "/advertisers",
  },
  {
    key: "ANALITICS",
    label: "ANALYTICS",
    url: "/analytics",
  },
  {
    key: "JOBS",
    label: "JOBS",
    url: "/jobs",
  },
  {
    key: "EVENTS",
    label: "EVENTS",
    url: "/events",
  },
  {
    key: "BUDGETOPT",
    label: "BUDGETOPT",
    url: "/budgetopt",
  },
  {
    key: "USER_ACTIVITIES",
    label: "USER ACTIVITIES",
    url: "/user-activities",
  },
  {
    key: "OVERVIEW",
    label: "OVERVIEW",
    url: "/overview",
  },
];

export const RestrictedLayout = ({ children }) => {
  let navigate = useNavigate();
  const adCubeAdminContext = useContext(ADCubeAdminContext);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => navigate("/")}
          >
            <img alt="adcube-logo" src={ADcubeLogoDark} width={100} />
          </IconButton>

          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            {MENU_ITEM.map((menuItem) => (
              <NavLink
                key={menuItem.key}
                to={menuItem.url}
                style={({ isActive }) => ({
                  cursor: "pointer",
                  margin: "0 1rem",
                  color: "white",
                  fontWeight: "bold",
                  textDecoration: isActive ? "underline" : "none",
                })}
              >
                {menuItem.label}
              </NavLink>
            ))}
          </Box>

          <Box display="flex" alignItems="center" gap={2}>
            <ThemeSwitcher />
            <Tooltip title="Termina la sessione">
              <Button
                color="inherit"
                onClick={() => {
                  ADCubeConfigService.logout();
                  adCubeAdminContext.setAuthenticated(false);
                }}
              >
                LOGOUT
              </Button>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      {children}
    </Box>
  );
};
