import Chart from "react-apexcharts";

const areaColors = {
  series1: "#7367F0",
  series2: "#000000",
};

export const TemporalBudgetChart = ({ itemTimeseries }) => {
  let bugetsOnPlatform = [];
  let selectedBudgets = [];
  let timeList = [];
  //Stores in budgetsOnPlatform the list of the budgets on platform
  itemTimeseries.budgets_on_platform.forEach((item) => {
    bugetsOnPlatform.push(item[1]);
    timeList.push(item[0]);
  });

  //Stores in budgetsOnPlatform the list of the selected budgets
  itemTimeseries.selected_budgets.forEach((item) =>
    selectedBudgets.push(item[1])
  );

  const options = {
    chart: {
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      foreColor: "#B4B7BD",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      dashArray: [0, 12],
      width: [3, 2.5],
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    colors: [areaColors.series1, areaColors.series2],
    xaxis: {
      type: "datetime",
      title: {
        text: "Date"
      },
      categories: timeList,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: [0.7, 0],
        opacityTo: [0.5, 0],
        stops: [0, 80, 100],
      },
    },
    tooltip: {
      shared: true,
    },
    yaxis: {
      opposite: false,
      title: {
        text: "Budget"
      },
      labels: {
        formatter: (val) => {
          return val;
        },
      },
    },
  };

  let series = [
    {
      name: "Platform's budget",
      data: bugetsOnPlatform,
    },
    {
      name: "Budopt's budget ",
      data: selectedBudgets,
    },
  ];

  return <Chart options={options} series={series} type="area" height={350} />;
};
