import { Container } from "@mui/material";

export const GuestLayout = ({ children }) => {
  return (
    <Container
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      {children}
    </Container>
  );
};
