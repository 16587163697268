import DateRangeIcon from "@mui/icons-material/DateRange";
import { Box } from "@mui/material";
import moment from "moment";
import { useRef } from "react";
import Flatpickr from "react-flatpickr";

export const DateFilter = ({ onDateChange, dateRange, maximumDate }) => {
  const datePicker = useRef(null);

  const onDateRangeChange = (values) => {
    if (values.length === 2) {
      onDateChange(values.map((d) => moment(d).format("YYYY-MM-DD")));
    }
  };

  return (
    <Box display="flex" gap={1} alignItems="center">
      <DateRangeIcon
        fontSize="large"
        style={{ cursor: "pointer" }}
        onClick={() => {
          datePicker.current.flatpickr.open();
        }}
      />
      <Flatpickr
        ref={datePicker}
        style={{
          minWidth: 200,
          textAlign: "center",
          minHeight: "2rem",
          borderRadius: 5,
        }}
        options={{
          showMonths: 2,
          mode: "range",
          maxDate: maximumDate,
          defaultDate: dateRange,
          dateFormat: "d-m-Y",
        }}
        onChange={onDateRangeChange}
      />
    </Box>
  );
};
