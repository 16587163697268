import { CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ADCUBE_ENTITY } from "../../../../common/const/ADcubeEntity";
import { OrganizationTree } from "../../../../components/organization-tree/OrganizationTree";
import { PageCard } from "../../../../components/PageCard";
import { PageContent } from "../../../../components/PageContent";
import { ApiService } from "../../../../service/ApiService";
import { UsersActivity } from "./UserActivities";

export const AdvertiserTree = () => {
  const [loading, setLoading] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const loadTree = () => {
    setSelectedEntity(null);
    setLoading(true);

    ApiService.getOrganizationTree()
      .then((responseCompanyList) => {
        setCompanyList(responseCompanyList);
      })
      .catch()
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadTree();
  }, []);

  return (
    <PageContent>
      {loading ?
        <CircularProgress />
        :
        <PageCard title="Users overview">
          <Grid container marginTop={1}>
            <Grid item xs={6}>
              <OrganizationTree
                companyList={companyList}
                entitities={[
                  ADCUBE_ENTITY.COMPANY,
                  ADCUBE_ENTITY.ADVERTISER,
                  ADCUBE_ENTITY.PROJECT,
                ]}
                onEntityClick={setSelectedEntity}
              />
            </Grid>
            <Grid item xs={6}>
              {selectedEntity ?
                <UsersActivity
                  selectedEntity={selectedEntity}
                  key={selectedEntity.type + "_" + selectedEntity.id}
                /> :
                <Typography>Select an entity to show users overview</Typography>
              }
            </Grid>
          </Grid>
        </PageCard>
      }
    </PageContent>
  );
};
