import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { PermissionUtils } from "../../../../common/utils/PermissionUtils";
import { PROJECT_PERMISSION } from "../../../../common/const/ProjectPermission";

export const UserPermissionList = ({ permissionCode }) => {
  const permissionList =
    PermissionUtils.getPermissionListFromCode(permissionCode);

  return (
    <Box paddingX={3} paddingBottom={1}>
      {PermissionUtils.isAllPermission(permissionCode) ? (
        <Typography>
          Permission: <strong>ALL</strong>
        </Typography>
      ) : permissionCode === 0 ? (
        <Typography>
          Permission: <strong>NONE</strong>
        </Typography>
      ) : (
        <Grid container >
          {Object.keys(PROJECT_PERMISSION).map((permission) => (
            <Grid item key={permission} xs={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {permissionList.includes(permission) ? (
                  <CheckIcon color="success" />
                ) : (
                  <CloseIcon color="error" />
                )}
                <Typography variant="caption">{PROJECT_PERMISSION[permission].label}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
