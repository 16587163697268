import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { PROJECT_PERMISSION } from "../../../../common/const/ProjectPermission";
import { PermissionUtils } from "../../../../common/utils/PermissionUtils";
import { ApiService } from "../../../../service/ApiService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ChangeUserPermissionModal = ({
  open,
  onClose,
  user,
  projectId,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [permissionList, setPremissionList] = useState(
    PermissionUtils.getPermissionListFromCode(user.permissions)
  );

  const updateUserPermissionApi = () => {
    const newPermissionCode =
      PermissionUtils.getCodeFromPermissionList(permissionList);
    setDisabled(true);
    ApiService.updateUserProjectPermissionCode(
      user.user_id,
      projectId,
      newPermissionCode
    ).finally(() => {
      onClose(true);
    });
  };

  const togglePermission = (permission) => {
    let clonePermissionList = [...permissionList];
    if (clonePermissionList.includes(permission)) {
      clonePermissionList.splice(clonePermissionList.indexOf(permission), 1);
    } else {
      clonePermissionList.push(permission);
    }
    setPremissionList(clonePermissionList);
  };
  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Update user permission list
        </Typography>
        <Box paddingX={0} paddingY={1}>
          {Object.keys(PROJECT_PERMISSION).map((permission) => (
            <Box
              style={{
                display: "flex",
                margin: "1rem",
                cursor: "pointer",
              }}
              key={permission}
              onClick={() => togglePermission(permission)}
            >
              <Typography style={{ flexGrow: 1 }}>
                {PROJECT_PERMISSION[permission].label}
              </Typography>
              {permissionList.includes(permission) ? (
                <CheckIcon color="success" />
              ) : (
                <CloseIcon color="error" />
              )}
            </Box>
          ))}
        </Box>
        <Box style={{ textAlign: "center" }}>
          <Button
            onClick={updateUserPermissionApi}
            variant="contained"
            disabled={disabled}
          >
            SAVE
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
