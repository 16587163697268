import BadgeIcon from "@mui/icons-material/Badge";
import BusinessIcon from "@mui/icons-material/Business";
import CampaignIcon from "@mui/icons-material/Campaign";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import FolderIcon from "@mui/icons-material/Folder";
import PersonIcon from "@mui/icons-material/Person";
import { Tooltip } from "@mui/material";
import { ADCUBE_ENTITY } from "./ADcubeEntity";

export const ADCUBE_ENTITY_ICON = {
  [ADCUBE_ENTITY.USER]: (
    <Tooltip title="User">
      <PersonIcon />
    </Tooltip>
  ),
  [ADCUBE_ENTITY.COMPANY]: (
    <Tooltip title="Company">
      <BusinessIcon />
    </Tooltip>
  ),
  [ADCUBE_ENTITY.ADVERTISER]: (
    <Tooltip title="Advertiser">
      <BadgeIcon />
    </Tooltip>
  ),
  [ADCUBE_ENTITY.PROJECT]: (
    <Tooltip title="Project">
      <FolderIcon />
    </Tooltip>
  ),
  [ADCUBE_ENTITY.CAMPAIGN]: (
    <Tooltip title="Campaign">
      <CampaignIcon />
    </Tooltip>
  ),
  [ADCUBE_ENTITY.ADGROUP]: (
    <Tooltip title="Adgroup">
      <DynamicFeedIcon />
    </Tooltip>
  ),
};
