import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { ADCUBE_ENTITY } from "../../../common/const/ADcubeEntity";
import { OrganizationTree } from "../../../components/organization-tree/OrganizationTree";
import { PageCard } from "../../../components/PageCard";
import { PageContent } from "../../../components/PageContent";
import { ApiService } from "../../../service/ApiService";
import { ADcubeSummary } from "./components/ADcubeSummary";

export const OrganizationPage = () => {
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const loadTree = () => {
    setLoading(true);

    ApiService.getOrganizationTree()
      .then((responseCompanyList) => {
        setCompanyList(responseCompanyList);
      })
      .catch(() => {
        setCompanyList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadTree();
  }, []);

  return (
    <PageContent>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <PageCard title="Summary">
            <ADcubeSummary companyList={companyList} />
          </PageCard>
          <PageCard title="Entity Tree">
            <OrganizationTree
              companyList={companyList}
              entitities={[
                ADCUBE_ENTITY.COMPANY,
                ADCUBE_ENTITY.ADVERTISER,
                ADCUBE_ENTITY.PROJECT,
                ADCUBE_ENTITY.ITEM,
              ]}
            />
          </PageCard>
        </>
      )}
    </PageContent>
  );
};
