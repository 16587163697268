export const PROJECT_PERMISSION = {
  VIEW: {
    code: 1 << 0,
    label: "View Analytics/Overview",
  },
  VIEW_SPENDOPT: {
    code: 1 << 1,
    label: "View SpendOpt",
  },
  EDIT_SPENDOPT: {
    code: 1 << 2,
    label: "Edit SpendOpt",
  },
  VIEW_BUDOPT: {
    code: 1 << 3,
    label: "View BudOpt",
  },
  EDIT_BUDOPT: {
    code: 1 << 4,
    label: "Edit BudOpt",
  },
  MANAGE_ITEMS: {
    code: 1 << 5,
    label: "Manage Items",
  },
  // Can put the project in a paused or active state
  CHANGE_STAGE: {
    code: 1 << 6,
    label: "Edit project stage",
  },

  WRITE_BUDGETS_TO_PLATFORMS: {
    code: 1 << 7,
    label: "write budet platform",
  },

  VIEW_TARGOPT: {
    code: 1 << 8,
    label: "View Actionable insights",
  },
  EDIT_SETTINGS: {
    code: 1 << 9,
    label: "Edit settings",
  },
  VIEW_AUDIENCE_DISCOVERY_INSIGHTS : {
    code: 1 << 10,
    label: "View audience discovery",
  },
};
