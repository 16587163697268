import ReactApexChart from "react-apexcharts";

export const GraphRanges = ({ xData, yLq, yMn, yUq, pointsX, pointsY, xLabel, yLabel, title }) => {

  let points = []
  let rangesData=[]
  let medianData=[]
  let series = [
    {
      type: 'rangeArea',
      name: 'Range',
      data: rangesData
    },
    {
      type: 'line',
      name: 'Median',
      data: medianData
    },
  ]

  //Load the lower and upper ranges and also the curves data,
  xData.forEach((num1, index) => {
    rangesData.push({
      x : num1,
      y : [yLq[index].toFixed(2),yUq[index].toFixed(2)]
    })
    medianData.push({
      x: num1.toFixed(2),
      y: yMn[index].toFixed(2)
    })
  });
  //Load the points data
  pointsX.forEach((num1, index) => {
    points.push({
      x: pointsX[index],
      y: pointsY[index],
      marker: {
        size: 1,
        fillColor: '#fff',
        strokeColor: 'blue',
        radius: 2,
        cssClass: 'apexcharts-custom-class'
      }
    })
  });

  let options = {
    chart: {
      height: 350,
      type: 'rangeArea',
      animations: {
        speed: 500
      },
      foreColor: "#B4B7BD"
    },
    colors: ['#7267f0'],
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: [0.24, 1]
    },
    stroke: {
      curve: 'smooth',
      width: [0, 2]
    },
    title: {
      text: title
    },
    xaxis: {
      tickAmount: 8,
      title: {
        text: xLabel,
      },
      labels: {
        formatter: (val) => {
          return val;
        },
      },
    },
    yaxis: {
      title: {
        text: yLabel,
        labels: {
          formatter: (val) => {
            return val;
          },
        },
      },
    },
    markers: {
      hover: {
        sizeOffset: 5
      }
    },
    annotations: {
      points: points
    }
  }

  return (<ReactApexChart options={options} series={series} type="rangeArea" height={350} />)
}
