import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { Box, Tooltip } from "@mui/material";
import { useContext } from "react";
import { ADCubeAdminContext } from "../context/ADCubeAdminContext";
import { ADCubeConfigService } from "../service/ADCubeConfigService";

export const ThemeSwitcher = () => {
  const adCubeAdminContext = useContext(ADCubeAdminContext);

  const toggleTheme = () => {
    ADCubeConfigService.setLightTheme(!adCubeAdminContext.themeLight);
    adCubeAdminContext.setThemeLight(!adCubeAdminContext.themeLight);
  };

  return (
    <Tooltip
      title={adCubeAdminContext.themeLight ? "Dark Theme" : "Light Theme"}
    >
      <Box
        onClick={toggleTheme}
        sx={{ cursor: "pointer" }}
        display="flex"
        alignItems="center"
      >
        {adCubeAdminContext.themeLight ? <DarkModeIcon /> : <LightModeIcon />}
      </Box>
    </Tooltip>
  );
};
