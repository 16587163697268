const LOCAL_STORAGE_KEY = "ADcube-admin-panel-config";

const DEFAULT_CONFIG = {
  accessKey: null,
  lightTheme: true,
};

let adCubeConfig = null;
try {
  adCubeConfig = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  if (adCubeConfig == null) {
    adCubeConfig = { ...DEFAULT_CONFIG };
  }
} catch (error) {
  adCubeConfig = adCubeConfig = { ...DEFAULT_CONFIG };
}

const setAccesKey = (accessKey) => {
  adCubeConfig.accessKey = accessKey;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(adCubeConfig));
};
const setLightTheme = (lightTheme) => {
  adCubeConfig.lightTheme = lightTheme;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(adCubeConfig));
};

const logout = () => {
  adCubeConfig.accessKey = null;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(adCubeConfig));
};

export const ADCubeConfigService = {
  getAccessKey: () => adCubeConfig.accessKey,
  setAccesKey,
  getLightTheme: () => adCubeConfig.lightTheme,
  setLightTheme,
  logout,
  isLogged: () => adCubeConfig?.accessKey !== null,
};
