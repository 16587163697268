// Styling for Google charts. These are hardcoded because they
// are drawn as part of the svg so it is hard to style them
// from the css as usual

const getLightStyles = () => {
  return {
    fontFamily: '"Montserrat", Helvetica, Arial, serif',
    foregroundText: '#6e6b7b',
    foregroundLines: '#e7e5ea',
    background: '#FFFFFF',
    fontSize: 11
  }
}

const getDarkStyles = () => {
  return {
    fontFamily: '"Montserrat", Helvetica, Arial, serif',
    foregroundText: '#b4b7bd',
    foregroundLines: '#464647',
    background: '#161D31',
    fontSize: 11
  }
}

export { getLightStyles, getDarkStyles }