import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";

const FORM_STYLE = { minWidth: "15%", maxWidth: "15%" };

export const BudOptItemSelector = ({
  companyList,
  onProjectChange,
  onItemChange,
}) => {
  const activeStatus = ["active", "campaign_status_enabled"];

  const [optionList, setOptionList] = useState({
    companies: [],
    advertisers: [],
    projects: [],
    items: [],
  });
  const [selectedValue, setSelectedValue] = useState({
    company: "none",
    advertiser: "none",
    project: "none",
    item: "none",
  });

  useEffect(() => {
    setOptionList((old) => ({
      ...old,
      companies: companyList.map((c) => ({ id: c.company_id, name: c.name })),
    }));
  }, [companyList]);

  const handleCompanyChange = (e) => {
    const selectedCompany = e.target.value;
    setSelectedValue(() => ({
      company: selectedCompany,
      advertiser: "none",
      project: "none",
      item: "none",
    }));

    setOptionList((old) =>
      selectedCompany !== "none"
        ? {
            ...old,
            advertisers: companyList
              .find((c) => c.company_id === selectedCompany)
              .advertisers.map((a) => ({ id: a.advertiser_id, name: a.name })),
          }
        : {
            ...old,
            advertisers: [],
            projects: [],
            items: [],
          }
    );
    onProjectChange(null);
  };

  const handleAdvertiserChange = (e) => {
    const selectedAdvertiser = e.target.value;
    setSelectedValue((old) => ({
      ...old,
      advertiser: selectedAdvertiser,
      project: "none",
      item: "none",
    }));

    setOptionList((old) =>
      selectedAdvertiser !== "none"
        ? {
            ...old,
            projects: companyList
              .find((c) => c.company_id === selectedValue.company)
              .advertisers.find((a) => a.advertiser_id === selectedAdvertiser)
              .projects.map((p) => ({ id: p.project_id, name: p.name })),
          }
        : {
            ...old,
            projects: [],
            items: [],
          }
    );
    onProjectChange(null);
  };

  const handleProjectChange = (e) => {
    const selectedProject = e.target.value;
    setSelectedValue((old) => ({
      ...old,
      project: selectedProject,
      item: "none",
    }));

    if (selectedProject !== "none") {
      const itemList = companyList
        .find((c) => c.company_id === selectedValue.company)
        .advertisers.find((a) => a.advertiser_id === selectedValue.advertiser)
        .projects.find((p) => p.project_id === selectedProject)
        .items.filter((item) => activeStatus.includes(item.item_data.status))
        .map((i) => ({
          id: i.item_data.item_id,
          name: i.item_data.name,
        }));
      setOptionList((old) => ({
        ...old,
        items: itemList,
      }));
      onProjectChange({
        advertiserId: selectedValue.advertiser,
        projectId: selectedProject,
        itemList: itemList.map((i) => i.id),
      });
    } else {
      setOptionList((old) => ({
        ...old,
        items: [],
      }));
      onProjectChange(null);
    }
  };

  const handleItemChange = (e) => {
    let selectedItem = e.target.value;
    setSelectedValue((old) => ({
      ...old,
      item: selectedItem,
    }));

    if (selectedItem !== "none") {
      onItemChange({
        advertiserId: selectedValue.advertiser,
        projectId: selectedValue.project,
        itemId: selectedItem,
      });
    } else {
      onItemChange({
        projectId: selectedValue.project,
        itemId: "none",
      });
    }
  };

  return (
    <Box
      style={{ display: "flex", gap: "1rem", alignItems: "center" }}
      component="div"
    >
      <FormControl sx={FORM_STYLE} size="small">
        <InputLabel id="company-label">Company</InputLabel>
        <Select
          labelId="company-label"
          id="company-select"
          value={selectedValue.company}
          onChange={handleCompanyChange}
          label="Company"
        >
          <MenuItem value="none">
            <em>-</em>
          </MenuItem>
          {optionList.companies.map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {optionList.advertisers.length > 0 ? (
        <FormControl sx={FORM_STYLE} size="small">
          <InputLabel id="advertiser-label">Advertiser</InputLabel>
          <Select
            labelId="advertiser-label"
            id="advertiser-select"
            value={selectedValue.advertiser}
            onChange={handleAdvertiserChange}
            label="Advertiser"
          >
            <MenuItem value="none">
              <em>-</em>
            </MenuItem>
            {optionList.advertisers.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}

      {optionList.projects.length > 0 ? (
        <FormControl sx={FORM_STYLE} size="small">
          <InputLabel id="project-label">Project</InputLabel>
          <Select
            labelId="project-label"
            id="project-select"
            value={selectedValue.project}
            onChange={handleProjectChange}
            label="Project"
          >
            <MenuItem value="none">
              <em>-</em>
            </MenuItem>
            {optionList.projects.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}

      {optionList.items.length > 0 ? (
        <FormControl sx={FORM_STYLE} size="small">
          <InputLabel id="item-label">Item</InputLabel>
          <Select
            labelId="item-label"
            id="item-select"
            value={selectedValue.item}
            onChange={handleItemChange}
            label="Item"
          >
            <MenuItem value="none">
              <em>-</em>
            </MenuItem>
            {optionList.items.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name.slice(0, 40)}
                {c.name.length > 40 ? "..." : ""}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}
    </Box>
  );
};
