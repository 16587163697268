import { PageContent } from "../../../components/PageContent";
import { EventBox } from "./components/EventBox";
import { useEffect, useState } from "react";
import { ApiService } from "../../../service/ApiService";
import { CircularProgress } from "@mui/material";
import { PageCard } from "../../../components/PageCard";
import { DEFAULT_DATE } from "../../../common/const/DefaultDateRange";
import { DateFilter } from "../../../components/filters/DateFilter";
import { EventsUtils } from "../../../common/utils/EventsUtils";
import { NoEventsBox } from "./components/NoEventsBox";
import { ErrorBox } from "./components/ErrorBox";
import { Box } from "@mui/material";
import { EventsItemSelector } from "./components/EventsItemSelector";


export const EventsPage = () => {
  const [loadingOrganizationTree, setLoadingOrganizationTree] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  
  const [companyList, setCompanyList] = useState(null);
  const [eventsList, setEventsList] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [dateFilterData, setDateFilterData] = useState(
    DEFAULT_DATE.EVENTS_FILTER_RANGE_DATE
  );

  const refreshEvents =(dates, projectId) => {
    setLoadingEvents(true);
    ApiService.getEvents(dates[0], dates[1], projectId)
      .then((events) => {
        setEventsList(events);
      })
      .catch(() => {
        console.log("ERRORE nel refresh event list");
        setEventsList(null);
      })
      .finally(() => {
        setLoadingEvents(false);
      });
  };

  const loadOrganizationTree = () => {
    setLoadingOrganizationTree(true);
    ApiService.getOrganizationTree()
      .then((companies) => {
        setCompanyList(companies);
      })
      .catch(() => {
        console.log("ERRORE nel caricamento di organiation tree");
        setCompanyList(null);
      })
      .finally(() => {
        setLoadingOrganizationTree(false);
      });
  }

  useEffect(() => {
    loadOrganizationTree();
  }, []);

  return (
    <PageContent>
      {loadingOrganizationTree ? (
        <CircularProgress />
      ) : companyList === null ? (
        <ErrorBox></ErrorBox>
      ) : (
        <>
          <PageCard title="Events Filter">
            <Box display="flex" alignItems="center" my={1}>
              <Box flexGrow={1}>
                <EventsItemSelector 
                companyList={companyList}
                onEntityChange= {(selProject) => {
                  setSelectedProject(selProject);
                  refreshEvents(dateFilterData,selProject);
                }}
                />
              </Box>
              <DateFilter
                onDateChange={(dates) => {
                  setDateFilterData(dates);
                  if (selectedProject != null) refreshEvents(dates, selectedProject);
                  
                }}
                dateRange={DEFAULT_DATE.EVENTS_FILTER_RANGE_DATE}
                maximumDate={DEFAULT_DATE.EVENTS_FILTER_END_DATE.toDate()}
              />
            </Box>
          </PageCard>
          {
            loadingEvents ? (
              <CircularProgress />
            ) :
          (
          eventsList== null || eventsList.length === 0   ? (
            <NoEventsBox selectedProject={selectedProject} ></NoEventsBox>
          ) : 
          (
            <>
              {eventsList.map((item) => (
                <EventBox
                  key={item.project_id}
                  projectName={EventsUtils.getProjectName(
                    item.project_id,
                    companyList
                  )}
                  date={item.date}
                  eventType={item.event_type}
                  projectItems={EventsUtils.getEventsBudget(item)}
                  campaignNameList={EventsUtils.getCampaignNames(
                    item.project_id,
                    companyList
                  )}
                ></EventBox>
                  ))}
            </>
          ))}
        </>
      )}
    </PageContent>
  );
};
