import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ADCUBE_ENTITY } from "../../../../common/const/ADcubeEntity";
import { OrganizationTree } from "../../../../components/organization-tree/OrganizationTree";
import { PageCard } from "../../../../components/PageCard";
import { ApiService } from "../../../../service/ApiService";
import { AdvertiserActionCard } from "./components/AdvertiserActionCard";
import { AdvertisersPageUtils } from "../utils/AdvertisersPageUtils";

export const AdvertiserManagement = () => {
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const loadTree = () => {
    setSelectedEntity(null);
    setLoading(true);
    ApiService.getOrganizationTree()
      .then((companyListResponse) => {
        setCompanyList(
          AdvertisersPageUtils.enrichCompanyList(companyListResponse)
        );
      })
      .catch(() => {
        setCompanyList([]);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadTree();
  }, []);

  const onEntityClick = (entity) => {
    if (entity?.type === ADCUBE_ENTITY.ADVERTISER) {
      const company = companyList.find(
        (c) => c.company_id === entity.company_id
      );
      const advertiser = company.advertisers.find(
        (a) => a.advertiser_id === entity.id
      );
      advertiser['type'] = entity?.type
      // Not sure why i cannot pass directly entity 
      setSelectedEntity(advertiser);
    }
    else if (entity?.type === ADCUBE_ENTITY.PROJECT) {
      setSelectedEntity(entity)
    }
    else {
      setSelectedEntity(null);
    }
  };

  return (
    <PageCard title="Entity Tree">
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container>
          <Grid item xs={6}>
            <OrganizationTree
              companyList={companyList}
              entitities={[
                ADCUBE_ENTITY.COMPANY,
                ADCUBE_ENTITY.ADVERTISER,
                ADCUBE_ENTITY.PROJECT,
              ]}
              onEntityClick={onEntityClick}
            />
          </Grid>
          <Grid item xs={6}>
            <AdvertiserActionCard
              entity={selectedEntity}
              reloadTree={loadTree}
            />
          </Grid>
        </Grid>
      )}
    </PageCard>
  );
};
