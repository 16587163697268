import { Box, Paper, Typography } from "@mui/material";

export const PageCard = ({ title, children }) => {
  return (
    <Box my={1}>
      <Paper border={1} elevation={5}>
        {title ? (
          <Box px={2} pt={1}>
            <Typography variant="h6" textTransform="capitalize">
              {title}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        <Box px={2} pb={1} pt={title ? 0 : 1}>
          {children}
        </Box>
      </Paper>
    </Box>
  );
};
