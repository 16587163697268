import EditIcon from "@mui/icons-material/Edit";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ADCUBE_ENTITY } from "../../../../common/const/ADcubeEntity";
import { ADCUBE_ENTITY_ICON } from "../../../../common/const/ADcubeEntityIcon";
import { DEFAULT_DATE } from "../../../../common/const/DefaultDateRange";
import { ApiService } from "../../../../service/ApiService";
import moment from "moment";

export const UserActivity = ({ user }) => {
  const startDate = DEFAULT_DATE.OVERVIEW_RANGE[0];
  const endDate = DEFAULT_DATE.OVERVIEW_RANGE[1];
  const [loading, setLoading] = useState(false);
  const [userActivities, setUserActivities] = useState(null);

  const getLastAction = (activities, action) => {
    const userActivitiesDict = {
      access: ["access"],
      modify: ["manage-project-items", "update-project-constraints"],
      write: ["optimize-platforms"],
    };
    for (const activity of activities) {
      if (userActivitiesDict[action].includes(activity.action))
        return moment(activity.timestamp).format("YYYY-MM-DD");
    }
    return "Not available"
  };
  useEffect(() => {
    setLoading(true);
    ApiService.getUserActivity(startDate, endDate, user.user_id, "action")
      .then((response) => {
        setUserActivities(response);
      })
      .catch((error) => {
        console.log("errore getting user activity", error);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Paper elevation={3} style={{ marginBottom: "1rem" }}>
      <Grid container>
        {loading ? (
          <CircularProgress />
        ) : !userActivities ? <Typography>Errore nel caricamento delle attività dell'utente</Typography> :
        (
          <>
            <Grid item xs={11}>
              <Box
                style={{
                  padding: "1rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                {ADCUBE_ENTITY_ICON[ADCUBE_ENTITY.USER]}
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>
                      {user.name} {user.surname}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      Last access: {getLastAction(userActivities, "access")}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      Last write: {getLastAction(userActivities, "write")}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      Last modify: {getLastAction(userActivities, "modify")}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={1}></Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};
