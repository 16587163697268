import EditIcon from "@mui/icons-material/Edit";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { ADCUBE_ENTITY } from "../../../../common/const/ADcubeEntity";
import { ADCUBE_ENTITY_ICON } from "../../../../common/const/ADcubeEntityIcon";
import { UserRole } from "../../../../components/UserRole";
import { UserPermissionList } from "./UserPermissionList";

export const UserPermissionCard = ({ user, type, openEditPermissionModal }) => {
  return (
    <Paper elevation={3} style={{ marginBottom: "1rem" }}>
      <Grid container>
        <Grid item xs={11}>
          <Box
            style={{
              padding: "1rem",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {ADCUBE_ENTITY_ICON[ADCUBE_ENTITY.USER]}
            <Typography>
              {user.name} {user.surname}
            </Typography>
            {type !== ADCUBE_ENTITY.PROJECT ? (
              <UserRole role={user.role} />
            ) : (
              <></>
            )}
          </Box>
          {type === ADCUBE_ENTITY.PROJECT ? (
            <UserPermissionList permissionCode={user.permissions} />
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={1}>
          {!user.is_admin ? (
            <Box
              style={{
                padding: "1rem",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <EditIcon onClick={() => openEditPermissionModal(type, user)} />
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
