import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { App } from "./components/App";
import "./index.scss";

import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Italian } from "flatpickr/dist/l10n/it";
flatpickr.localize(Italian);

console.log(
  "%c AD cube Admin %c" + process.env.REACT_APP_ENV_NAME + " ",
  "background: #000000; color: #FFFFFF; font-size:30px",
  "background: #000000; color: #FF0000; font-size:30px"
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
