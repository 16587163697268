import { Chip } from "@mui/material";
import { USER_ROLE_TYPE } from "../common/const/UserRoleType";

const ROLE_COLOR_MAPPING = {
  [USER_ROLE_TYPE.admin]: "error",
  [USER_ROLE_TYPE.owner]: "success",
  [USER_ROLE_TYPE.guest]: "warning",
  [USER_ROLE_TYPE.standard]: "primary",
};

export const UserRole = ({ role, style = {}, enabled = true, onClick = () => { } }) => {
  return <Chip clickable onClick={onClick} label={role} color={ROLE_COLOR_MAPPING[role]} style={style} variant={enabled ? "filled" : "outlined"} />;
};
