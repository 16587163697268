import DateRangeIcon from "@mui/icons-material/DateRange";
import { Box } from "@mui/material";
import moment from "moment";
import { useRef } from "react";
import Flatpickr from "react-flatpickr";
import { DEFAULT_DATE } from "../../../../../common/const/DefaultDateRange";

const DATE_FORMAT = "YYYY-MM-DD";

export const JobsDateFilter = ({ onDateChange }) => {
  const datePicker = useRef(null);

  const onDateRangeChange = (dateList) => {
    if (dateList.length === 2) {
      onDateChange(dateList.map((d) => moment(d).format(DATE_FORMAT)));
    }
  };

  return (
    <Box display="flex" gap={1} alignItems="center">
      <DateRangeIcon
        fontSize="large"
        style={{ cursor: "pointer" }}
        onClick={() => {
          datePicker.current.flatpickr.open();
        }}
      />
      <Flatpickr
        ref={datePicker}
        style={{
          minWidth: 200,
          textAlign: "center",
          minHeight: "2rem",
          borderRadius: 5,
        }}
        options={{
          showMonths: 2,
          mode: "range",
          maxDate: DEFAULT_DATE.JOB_FILTER_END_DATE.toDate(),
          defaultDate: [
            DEFAULT_DATE.JOB_FILTER_START_DATE.toDate(),
            DEFAULT_DATE.JOB_FILTER_END_DATE.toDate(),
          ],
          dateFormat: "d-m-Y",
        }}
        onChange={onDateRangeChange}
      />
    </Box>
  );
};
