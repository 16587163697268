import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import Chart from "react-apexcharts";
import { ADCubeAdminContext } from "../../../../../context/ADCubeAdminContext";

const areaColors = {
  series1: "#7367F0",
  series2: "#ebe9f1",
};

export const AnalyticsChart = ({ chartData, metric }) => {
  const adCubeAdminContext = useContext(ADCubeAdminContext);

  const dates = Object.keys(chartData);
  const series = [
    {
      name: metric,
      data: Object.values(chartData).map((v) => v[metric]),
    },
  ];

  const options = {
    theme: {
      mode: adCubeAdminContext.themeLight ? "light" : "dark",
    },
    chart: {
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      dashArray: 0,
      width: 3,
    },
    legend: {
      show: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    colors: [areaColors.series1, areaColors.series2],
    xaxis: {
      type: "datetime",
      categories: dates,
      tickAmount: dates.length < 10 ? dates.length : 10,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100],
      },
    },
    tooltip: {
      shared: false,
    },
    yaxis: {
      opposite: false,
    },
  };

  return (
    <Box border={1} borderRadius>
      <Typography textTransform="capitalize">{metric}</Typography>
      <Chart
        options={{ ...options }}
        series={series}
        type="area"
        height={350}
      />
    </Box>
  );
};
