import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { ApiService } from "../../../../service/ApiService";
import { ChangeUserPermissionModal } from "../../users/modal/ChangeUserPermissionListModal";
import { ChangeUserRoleModal } from "../../users/modal/ChangeUserRoleModal";
import { UserActivity } from "./UserActivity";



export const UsersActivity = ({ selectedEntity }) => {
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState(null);

  const [editRoleModalOpen, setEditRoleModalOpen] = useState(false);
  const [editPermissionModalOpen, setEditPermissionModalOpen] = useState(false);
  const [editUserModal, setEditUserModal] = useState(null);


  const loadUserListApi = useCallback((type, id) => {

    if (type === "company") {
      setLoading(true);
      ApiService.getUsersByCompany(id)
        .then((userList) => {
          setUserList(userList);
        })
        .catch(() => {
          setUserList(null);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (type === "advertiser") {
      setLoading(true);
      ApiService.getUsersByAdvertiser(selectedEntity.company_id, id)
        .then((response) => {
          setUserList(response);
        })
        .catch(() => {
          setUserList(null);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (type === "project") {
      setLoading(true);
      ApiService.getUsersByProject(selectedEntity.company_id, selectedEntity.advertiser_id, id)
        .then((response) => {
          setUserList(response);
        })
        .catch(() => {
          setUserList(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }

  }, []);

  useEffect(() => {
    loadUserListApi(selectedEntity.type, selectedEntity.id);
  }, [selectedEntity.type, selectedEntity.id, loadUserListApi]);

  return (
    <>
      <Box sx={{ padding: "0 1rem" }}>
        {loading ?
          <CircularProgress />
          : userList ?
            <Box>
              {userList ? (
                userList.map((user) => (
                  <UserActivity
                    user={user}
                    type={selectedEntity.type}
                    key={user.user_id}
                  />
                ))
              ) : (
                <Typography>Lista utenti vuota.</Typography>
              )}
            </Box>
            : <Typography>Non riesco a caricare la lista utenti</Typography>
        }
      </Box>
      {editUserModal && editPermissionModalOpen ?
        <ChangeUserPermissionModal
          user={editUserModal}
          open={editPermissionModalOpen}
          projectId={selectedEntity.id}
          onClose={(reload = false) => {
            setEditUserModal(null);
            setEditPermissionModalOpen(false);
            if (reload) {
              loadUserListApi(selectedEntity.type, selectedEntity.id);
            }
          }}
        />
        : ""}
      {editUserModal && editRoleModalOpen ? (
        <ChangeUserRoleModal
          user={editUserModal}
          open={editRoleModalOpen}
          entityType={selectedEntity.type}
          entityId={selectedEntity.id}
          onClose={(reload = false) => {
            setEditUserModal(null);
            setEditRoleModalOpen(false);
            if (reload) {
              loadUserListApi(selectedEntity.type, selectedEntity.id);
            }
          }}
        />
      ) : ""
      }
    </>
  );
};
