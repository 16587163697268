import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ApiService } from "../../../../service/ApiService";

const SERVER_STATUS = {
  OK: "ok",
  KO: "ko",
  WARNING: "warning",
};

export const NoCompanyAdvertiserWidget = () => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    ApiService.getNoCompanyAdvertisers()
      .then((noCompanyAdvertisers) => {
        setStatus(
          noCompanyAdvertisers.length === 0
            ? SERVER_STATUS.OK
            : SERVER_STATUS.WARNING
        );
      })
      .catch(() => {
        setStatus(SERVER_STATUS.KO);
      });
  }, []);

  return (
    <Box display="flex" gap={1} p={2}>
      <Typography flexGrow={1}>Advertisers with no company</Typography>
      {status === SERVER_STATUS.OK ? (
        <Typography color="success.main">OK</Typography>
      ) : status === SERVER_STATUS.KO ? (
        <Typography color="error.main">KO</Typography>
      ) : status === SERVER_STATUS.WARNING ? (
        <Typography color="warning.main">WARNING</Typography>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
