import { Button, Typography, Box, FormControl, InputLabel, MenuItem, Select, TextField, Snackbar, SnackbarOrigin } from "@mui/material";
import { AddToADcubeCompanyBox } from "./AddToAdcubeCompanyBox";
import { DeleteAdvertiserBox } from "./DeleteAdvertiserBox";
import { ApiService } from "../../../../../service/ApiService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useContext, useState } from "react";

export const AdvertiserActionCard = ({ entity, reloadTree }) => {

  const advertiserType = Object.freeze({
    Hotel: 'hotel',
    Other: 'other'
  });

  const [selectedAdvertiserType, setSelectedAdvertiserType] = useState(advertiserType.Other);

  const [selectedTrainingDays, setSelectedTrainingDays] = useState(45);

  const handleAdvertiserTypeChange = (v) => {
    setSelectedAdvertiserType(v.target.value)
  }

  const handleTrainingDaysChange = (v) => {
    setSelectedTrainingDays(v.target.value)
  }

  const generateActionableInsights = (advertiserId, projectId) => {
    toast.success('Message enqueued successfully!', {
      position: toast.POSITION.TOP_RIGHT
    })
    ApiService.generateActionableInsights(
      advertiserId,
      projectId
    ).then((response) => {
    }).catch((err) => {
      console.log("error", err);
    });
  }

  const generateCopilotSuggestions = (projectId) => {
    toast.success('Started generating suggestion successfully!', {
      position: toast.POSITION.TOP_RIGHT
    })
    ApiService.generateCopilotSuggestions(
      projectId
    ).then((response) => {
    }).catch((err) => {
      console.log("error", err);
    });
  }

  const updateAdvertiserType = (advertiserId, selectedAdvertiserType) => {
    toast.success('Advertiser type updates succesfully!', {
      position: toast.POSITION.TOP_RIGHT
    })
    ApiService.updateAdvertiserType(
      advertiserId,
      selectedAdvertiserType
    ).then((response) => {
    }).catch((err) => {
      console.log("error", err);
    });

  }

  const updateProjectTrainingDays = (advertiserId, projectId, nTrainingDays) => {
    toast.success('Project training days updated succesfully!', {
      position: toast.POSITION.TOP_RIGHT
    })
    ApiService.updateProjectTrainingDays(
      advertiserId,
      projectId,
      selectedTrainingDays
    ).then((response) => {
    }).catch((err) => {
      console.log("error", err);
    });
  }

  return (
    <Box mx={2}>
      <ToastContainer />
      {entity ?
        <Box>
          <Typography>
            {entity.type.charAt(0).toUpperCase() + entity.type.slice(1)} <strong>{entity.name}</strong>
          </Typography>

          {entity.type === "project" ?
            <Box>
              <Button color="success" variant="contained" onClick={() => generateActionableInsights(entity.advertiser_id, entity.id)}>
                GENERATE ACTIONABLE INSIGHTS
              </Button>
              <Button style={{marginLeft: "2rem"}} color="success" variant="contained" onClick={() => generateCopilotSuggestions(entity.id)}>
                GENERATE COPILOT SUGGESTIONS
              </Button>
              <Box my={2}>
                <TextField
                  id="filled-number"
                  label="Training days"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={selectedTrainingDays}
                  onChange={handleTrainingDaysChange}
                  variant="filled"
                />

                <Button style={{ marginLeft: "10px" }} color="success" variant="contained" onClick={() => updateProjectTrainingDays(entity.advertiser_id, entity.id)}>
                  SET Training Days
                </Button>
              </Box>

            </Box>
            : ""}

          {entity.warningNotInAdminCompany ? (
            <Box>
              <AddToADcubeCompanyBox
                advertiserId={entity.advertiser_id}
                advertiserName={entity.name}
                onAdd={reloadTree}
              />
            </Box>
          ) : ""}

          {entity.type === "advertiser" ?
            <Box my={2}>
              <Typography>Danger Zone</Typography>
              <DeleteAdvertiserBox
                advertiserId={entity.advertiser_id}
                advertiserName={entity.name}
                onDelete={reloadTree}
              />

              <InputLabel
                style={{ marginTop: "10px" }}
                id="advertiser-label">Advertiser type</InputLabel>
              <Select
                labelId="advertiser-label"
                id="advertiser-select"
                value={selectedAdvertiserType}
                onChange={handleAdvertiserTypeChange}
                label="Advertiser "
                className="react-select-container"
                style={{ width: "130px" }}
              >
                <MenuItem value={advertiserType.Hotel}>{advertiserType.Hotel}</MenuItem>
                <MenuItem value={advertiserType.Other}>{advertiserType.Other}</MenuItem>

              </Select>

              <Button style={{ marginLeft: "10px" }} color="success" variant="contained" onClick={() => updateAdvertiserType(entity.advertiser_id, selectedAdvertiserType)}>
                SET ADVERTISER TYPE
              </Button>

            </Box> : ""}

        </Box> : <Typography>Select advertiser</Typography>
      }
    </Box>
  );
};
