import { useState, useEffect, useContext } from "react";
import { Chart as GoogleChart } from "react-google-charts";
import { getLightStyles, getDarkStyles } from "./chartStyles";
import { renderToString } from "react-dom/server";
import { ADCubeAdminContext } from "../../context/ADCubeAdminContext";
import { Box, Typography } from "@mui/material";

/*eslint-disable */
const LineChart = (props) => {
  const adCubeAdminContext = useContext(ADCubeAdminContext);

  const defaultOptions = {
    intervals: false,
    width: "100%",
    height: 500, // in pixels
    seriesColors: ["#28C76F", "#7367F0"],
    xAxis: {
      label: "X Axis Label",
      data: [1, 2, 3],
      isCurrency: false,
      currency: "EUR",
    },
    yAxis: {
      labels: ["Series 1", "Series 2"],
      data: [
        [2, 4, 6],
        [5, 10, 15],
      ],

      // These are only applied if 'intervals' is true
      lowerBounds: [
        [1, 3, 5],
        [4, 9, 14],
      ],
      upperBounds: [
        [3, 5, 7],
        [6, 11, 16],
      ],

      isCurrency: false,
      currency: "EUR",
    },
  };

  // These are the props that can be passed to this chart
  const userOptions = props.options ?? {};

  // Merge the default options and the ones that were
  // overridden by the user via the props
  const mergedOptions = { ...defaultOptions, ...userOptions };

  // Shorthands for nested fields
  const xLabel = mergedOptions.xAxis.label;
  const xData = mergedOptions.xAxis.data;
  const yLabels = mergedOptions.yAxis.labels;
  const yData = mergedOptions.yAxis.data;
  const lowerBounds = mergedOptions.yAxis.lowerBounds;
  const upperBounds = mergedOptions.yAxis.upperBounds;

  // The enabled series, by default an array of true values
  const [enabledSeries, setEnabledSeries] = useState(
    new Array(yLabels.length).fill(true)
  );

  // This redraws the chart when the skin changes, the window is resized
  // or one of the series is enabled/disabled by the user
  useEffect(() => {}, [enabledSeries]);

  useEffect(() => {
    // If we passed a different number of columns, we need to reset the enabledSeries
    if (yLabels.length !== enabledSeries.length) {
      setEnabledSeries(new Array(yLabels.length).fill(true));
    }
  }, [userOptions]);

  // Get the style
  const darkTheme = !adCubeAdminContext.themeLight;
  const style = darkTheme ? getDarkStyles() : getLightStyles();

  // Sanity checks on provided series data
  if (
    yLabels.length !== yData.length ||
    yData.some((s) => s.length !== xData.length)
  ) {
    return "Invalid series data!";
  }

  // Build the series in the format that google-charts wants
  const series = [];

  // Function that generates a tooltip as an HTML string
  // Takes the index of the current x value
  const generateTooltip = (index) => {
    try {
      const xValue = xData[index];
      const xCurrency = mergedOptions.xAxis.isCurrency
        ? mergedOptions.xAxis.currency
        : "";

      const yCurrency = mergedOptions.yAxis.isCurrency
        ? mergedOptions.yAxis.currency
        : "";

      return renderToString(
        <div
          className="p-1"
          style={{
            width: "300px",
            fontFamily: style.fontFamily,
            color: style.foregroundText,
            backgroundColor: style.background,
          }}
        >
          <p>
            <b>{xLabel}:</b> {xValue} {xCurrency}
          </p>
          {yData.map((seriesData, j) => {
            if (enabledSeries[j]) {
              return (
                <p key={`${yLabels[j]}${index}`}>
                  <span
                    className="bullet bullet-sm"
                    style={{
                      backgroundColor: mergedOptions.seriesColors[j],
                    }}
                  ></span>
                  &nbsp;<b>{yLabels[j]}:</b>
                  &nbsp;{seriesData[index].toFixed(2)} {yCurrency}
                  &nbsp;
                  {mergedOptions.intervals && (
                    <span>
                      {"[" +
                        lowerBounds[j][index].toFixed(2) +
                        ", " +
                        upperBounds[j][index].toFixed(2) +
                        "]"}
                    </span>
                  )}
                </p>
              );
            }
          })}
        </div>
      );
    } catch {
      return "Failed to generate the tooltip";
    }
  };

  // Google Charts expect the data as an array where the
  // first row defines the columns, e.g.
  // [x label, y1 label, y1 interval, y1 interval, y1 tooltip, y2 ...]
  // and then the other rows contain the data in order e.g.
  // [1, 5, 4, 6, 'tooltip', ...]

  // Add the first row
  const firstRow = [{ type: "number", label: xLabel }];
  yLabels.forEach((label, index) => {
    firstRow.push({ type: "number", label });

    if (mergedOptions.intervals) {
      firstRow.push({ type: "number", role: "interval" });
      firstRow.push({ type: "number", role: "interval" });
    }

    firstRow.push({ type: "string", role: "tooltip", p: { html: true } });
  });
  series.push(firstRow);

  // Add the data rows
  for (let i = 0; i < xData.length; i++) {
    const row = [];
    row.push(xData[i]);

    for (let j = 0; j < yData.length; j++) {
      if (enabledSeries[j]) {
        const seriesData = yData[j];
        row.push(seriesData[i]);

        if (mergedOptions.intervals) {
          row.push(lowerBounds[j][i]);
          row.push(upperBounds[j][i]);
        }
      } else {
        // If the series is disabled, push it as an array of 0s
        // This is a small hack to make it transition smoothly
        // from/to the bottom of the graph when we enable/disable it
        row.push(0);

        if (mergedOptions.intervals) {
          row.push(0);
          row.push(0);
        }
      }

      row.push(generateTooltip(i));
    }

    series.push(row);
  }

  // Build the array of ticks to display. This is done in order
  // to have the ticks on the X axis start at the first value
  let ticks = [];

  if (xData.length > 0) {
    const startTick = xData[0];
    const endTick = xData[xData.length - 1];
    const tickCount = 10;
    const tickInterval = (endTick - startTick) / tickCount;

    for (let i = 0; i < tickCount; i++) {
      ticks.push(startTick + i * tickInterval);
    }

    ticks.push(endTick);
  }

  // Round all ticks to the nearest integer and remove duplicates
  ticks = ticks
    .map((t) => Math.round(t))
    .filter(function (item, pos, array) {
      return !pos || item != array[pos - 1];
    });

  const options = {
    curveType: "function", // This makes the curve smooth
    // If the series is disabled, set lineWidth to 0 so it
    // will not be shown over the X axis of the chart when
    // all its data is set to 0
    series: mergedOptions.seriesColors.map((color, index) => ({
      color,
      lineWidth: enabledSeries[index] ? 3 : 0,
    })),
    height: mergedOptions.height,
    // This draws the intervals of confidence as a colored area (if enabled)
    intervals: { style: mergedOptions.intervals ? "area" : "none" },
    legend: "none",
    // Do not change the focusTarget, otherwise the tooltips don't appear on hover of the curves
    // if intervals are enabled
    focusTarget: "datum",
    backgroundColor: "transparent",
    chartArea: {
      width: "80%", // Leave this at 80% otherwise it doesn't display the vAxis labels on mobile
      height: "80%",
    },
    animation: {
      startup: true,
      easing: "linear",
      duration: 300,
    },
    hAxis: {
      title: xLabel,
      margin: 50,
      titleTextStyle: {
        color: style.foregroundText,
        italic: false,
        fontName: style.fontFamily,
        bold: true,
      },
      textStyle: {
        color: style.foregroundText,
        fontName: style.fontFamily,
        fontSize: style.fontSize,
      },
      baselineColor: "transparent",
      gridlines: {
        color: "transparent",
      },
      minorGridlines: {
        color: "transparent",
      },
      ticks: ticks, // Use our custom ticks instead of the automatic ones
      format: "#",
    },
    vAxis: {
      textStyle: {
        color: style.foregroundText,
        fontName: style.fontFamily,
        fontSize: style.fontSize,
      },
      gridlines: {
        color: style.foregroundLines,
      },
      minorGridlines: {
        color: "transparent",
      },
      baselineColor: style.foregroundText,
    },
    tooltip: {
      isHtml: true,
      textStyle: {
        fontName: style.fontFamily,
      },
    },
  };

  return (
    <Box>
      <Box>
        <div className="ml-auto mr-3">
          {yLabels.map((label, index) => (
            <Typography
              key={index}
              className="cursor-pointer mr-2"
              onClick={(_) => {
                const newEnabledSeries = enabledSeries.map((value, i) => {
                  if (i === index) {
                    return !value;
                  } else {
                    return value;
                  }
                });
                setEnabledSeries(newEnabledSeries);
              }}
            >
              <span
                className="bullet bullet-sm"
                style={{
                  backgroundColor: enabledSeries[index]
                    ? mergedOptions.seriesColors[index]
                    : "#ccc",
                }}
              ></span>{" "}
              {label}
            </Typography>
          ))}
        </div>
      </Box>
      <Box>
        {enabledSeries.some((v) => v) ? (
          <GoogleChart
            width={mergedOptions.width}
            height={`${mergedOptions.height}px`}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={series}
            options={options}
            rootProps={{ "data-testid": "1" }}
          />
        ) : (
          <span>Please select at least one data set</span>
        )}
      </Box>
    </Box>
  );
};

export default LineChart;
