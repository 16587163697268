import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Paper, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { JobErrorDisplay } from "./JobErrorDisplay";
import { JobStatusIcon } from "./JobStatusIcon";
import { JobTree } from "./JobTree";

export const JobRootItem = ({ jobRootData }) => {
  const [openJobDetail, setOpen] = useState(false);

  return (
    <Paper elevation={8} style={{ padding: "1rem 0" }}>
      <Box display="flex" gap={1} px={2} py={1}>
        <Box
          flexGrow={1}
          display="flex"
          gap={2}
          onClick={() => setOpen(!openJobDetail)}
          style={{ cursor: "pointer" }}
        >
          <JobStatusIcon status={jobRootData.status} />
          <Box>
            <Typography fontStyle="italic" fontSize="small">
              {moment(jobRootData.start_time * 1000).format(
                "DD-MM-YYYY HH:mm:ss"
              )}
            </Typography>
            {jobRootData.end_time ? (
              <Typography fontStyle="italic" fontSize="small">
                {moment(jobRootData.end_time * 1000).format(
                  "DD-MM-YYYY HH:mm:ss"
                )}
              </Typography>
            ) : (
              <></>
            )}
          </Box>
          <Box>
              <Typography>
                {jobRootData.project_name
                  ? `Project: ${jobRootData.project_name}`
                  : ""}
              </Typography>
              <Typography>
                {jobRootData.advertiser_name
                  ? `Advertiser: ${jobRootData.advertiser_name}`
                  : ""}
              </Typography>
          </Box>
          <Typography>{`Job: ${jobRootData.key}`}</Typography>
          <JobErrorDisplay jobData={jobRootData} />
        </Box>
        <Box
          style={{ cursor: "pointer" }}
          onClick={() => setOpen(!openJobDetail)}
        >
          {openJobDetail ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
      </Box>

      {openJobDetail ? <JobTree jobRootPrefix={jobRootData.key} /> : <></>}
    </Paper>
  );
};
