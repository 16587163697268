import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

const buildSummaryData = (companyList) => {
  const summaryData = {
    companyNumber: 0,
    advertiserNumber: 0,
    projectNumber: 0,
    itemNumber: 0,
  };
  companyList.forEach((c) => {
    summaryData.companyNumber++;
    c.advertisers.forEach((a) => {
      summaryData.advertiserNumber++;
      a.projects.forEach((p) => {
        summaryData.projectNumber++;
        p.items.forEach((i) => {
          summaryData.itemNumber++;
        });
      });
    });
  });
  return summaryData;
};

export const ADcubeSummary = ({ companyList }) => {
  const [summaryData, setSummaryData] = useState(null);

  useEffect(() => {
    setSummaryData(buildSummaryData(companyList));
  }, [companyList]);

  return summaryData ? (
    <Grid container>
      <Grid item xs={3}>
        Company number: {summaryData.companyNumber}
      </Grid>
      <Grid item xs={3}>
        Advertiser number: {summaryData.advertiserNumber}
      </Grid>
      <Grid item xs={3}>
        Project number: {summaryData.projectNumber}
      </Grid>
      <Grid item xs={3}>
        Item number: {summaryData.itemNumber}
      </Grid>
    </Grid>
  ) : (
    <>No data available</>
  );
};
