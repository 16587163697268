const buildNodeItem = (nodeList, node) => {
  const children = nodeList.filter(
    (n) =>
      n.keyParts.length === node.keyParts.length + 1 &&
      n.key.startsWith(node.key)
  );

  if (children.length > 0) {
    children.forEach((child) => buildNodeItem(nodeList, child));
    children.sort((a, b) => a.start_time - b.start_time);
    node.children = children;
  }
  return node;
};

const buildTree = (treeItemList) => {
  const enrichedTreeList = [...treeItemList].map((n) => ({
    ...n,
    keyParts: n.key.split("/"),
    children: [],
  }));
  const rootNode = enrichedTreeList.find((node) => node.keyParts.length === 1);

  return buildNodeItem(enrichedTreeList, rootNode);
};

export const JobUtils = {
  buildTree,
};
