import { PROJECT_PERMISSION } from "../const/ProjectPermission";

const getPermissionListFromCode = (pCode) => {
  const perms = [];
  for (const code in PROJECT_PERMISSION) {
    const codeValue = PROJECT_PERMISSION[code].code;
    if ((pCode & codeValue) === codeValue) {
      perms.push(code);
    }
  }
  return perms;
};

const getCodeFromPermissionList = (permissionList) => {
  let permissionCode = 0;
  for (const code in PROJECT_PERMISSION) {
    const codeValue = PROJECT_PERMISSION[code].code;
    if (permissionList.includes(code)) {
      permissionCode |= codeValue;
    }
  }

  return permissionCode;
};

const isAllPermission = (permissionCode) => {
  return permissionCode === 2 ** Object.keys(PROJECT_PERMISSION).length - 1
}

export const PermissionUtils = {
  getPermissionListFromCode,
  getCodeFromPermissionList,
  isAllPermission
};
