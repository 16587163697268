export const METRICS_TYPE = {
  COST: "cost",
  IMPRESSIONS: "impressions",
  CLICKS: "clicks",
  CONVERSIONS: "conversions",
  REVENUE: "revenue",
  PAGE_VIEW: "ga_pageViews",
  CPM: "cpm",
  CPC: "cpc",
  CPA: "cpa",
  ROAS: "roas",
};

export const ALL_METRICS_LIST = Object.values(METRICS_TYPE);
