import { Tooltip } from "@mui/material";
import React from "react";
import { JOB_STATUS_ICON_CONFIG } from "../../../../../common/const/JobStatusIconConfig";

export const JobStatusIcon = ({ status, style }) => {
  const IconComponent = JOB_STATUS_ICON_CONFIG[status].componentIcon;
  return (
    <Tooltip title={JOB_STATUS_ICON_CONFIG[status].tooltipTitle}>
      <IconComponent
        color={JOB_STATUS_ICON_CONFIG[status].color}
        style={style ? style : null}
      />
    </Tooltip>
  );
};
