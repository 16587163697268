import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { ApiService } from "../../../../../service/ApiService";
import { SpendoptChart } from "./SpendoptChart";

export const SpendoptChartContainer = ({ filterData }) => {
  const [projectOverview, setProjectOverview] = useState(null);
  const [spendoptData, setSpendoptData] = useState(null);

  useEffect(() => {
    if (filterData?.projectId) {
      Promise.all([
        ApiService.getProjectOverview(
          filterData.advertiserId,
          filterData.projectId
        ),
        ApiService.getSpendoptData(
          filterData.advertiserId,
          filterData.projectId,
        )      ])
        .then((responses) => {
          setProjectOverview(responses[0]?.projectOverview);
          setSpendoptData(responses[1]);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [filterData?.projectId, filterData?.advertiserId]);


  return (
    <Box>
      {filterData === null ? (
        <Box>Seleziona il progetto per vedere le curve di spendopt</Box>
      ) : projectOverview &&
        spendoptData ? (
        <SpendoptChart
          projectOverview={projectOverview}
          spendoptData={spendoptData}
        />
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
