import { Box } from "@mui/material";
import { JobsDateFilter } from "./JobDateFilter";
import { JobsStatusFilter } from "./JobStatusFilter";

export const JobsFilter = ({ onChangeDateFilter, onChangeStatusFilter }) => {
  return (
    <Box display="flex" my={1} justifyContent="space-between">
      <Box>
        <JobsDateFilter onDateChange={onChangeDateFilter} />
      </Box>
      <Box>
        <JobsStatusFilter onStatusChange={onChangeStatusFilter} />
      </Box>
    </Box>
  );
};
