import { Box } from "@mui/material";
import { PageCard } from "../../../../components/PageCard";

export const NoEventsBox = ({ selectedProject }) => {
  return (
    <PageCard title="Events">
      <Box display="flex" alignItems="center" my={1}>
        <Box flexGrow={1}> {selectedProject !== null ? "Non ci sono eventi con i filtri selezionati" : "Selezionare un progetto per visualizzare gli eventi"}</Box>
      </Box>
    </PageCard>
  );
};
