import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ADCubeAdminContext } from "../context/ADCubeAdminContext";
import { GuestLayout } from "../layout/GuestLayout";
import { RestrictedLayout } from "../layout/RestrictedLayout";
import { LoginPage } from "../pages/guest/login/LoginPage";
import { AdvertisersPage } from "../pages/restricted/advertisers/AdvertisersPage";
import { AnalyticsPage } from "../pages/restricted/analytics/AnalyticsPage";
import { HomePage } from "../pages/restricted/home/HomePage";
import { JobsPage } from "../pages/restricted/jobs/JobsPage";
import { EventsPage } from "../pages/restricted/events/EventsPage";
import { NotfoundPage } from "../pages/restricted/not-found-page/NotFoundPage";
import { OrganizationPage } from "../pages/restricted/organization/OrganizationPage";
import { UsersPage } from "../pages/restricted/users/UsersPage";
import { ADCubeConfigService } from "../service/ADCubeConfigService";
import { BudgetOptPage } from "../pages/restricted/budgetopt/BudgetOptPage";
import { UserActivitiesPage } from "../pages/restricted/user-activities/UserActivitiesPage";
import { OverviewPage } from "../pages/restricted/overview/OverviewPage";

export const App = () => {
  const [authenticated, setAuthenticated] = useState(
    ADCubeConfigService.isLogged()
  );
  const [themeLight, setThemeLight] = useState(
    ADCubeConfigService.getLightTheme()
  );

  const theme = createTheme({
    palette: {
      mode: themeLight ? "light" : "dark",
    },
  });

  return (
    <ADCubeAdminContext.Provider
      value={{ authenticated, setAuthenticated, themeLight, setThemeLight }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {authenticated ? (
          <RestrictedLayout>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/organization" element={<OrganizationPage />} />
              <Route path="/user-permission" element={<UsersPage />} />
              <Route path="/advertisers" element={<AdvertisersPage />} />
              <Route path="/analytics" element={<AnalyticsPage />} />
              <Route path="/jobs" element={<JobsPage />} />
              <Route path="/events" element={<EventsPage />} />
              <Route path="/budgetopt" element={<BudgetOptPage />} />
              <Route path="/user-activities" element={<UserActivitiesPage />} />
              <Route path="/overview" element={<OverviewPage />} />
              <Route path="*" element={<NotfoundPage />} />
            </Routes>
          </RestrictedLayout>
        ) : (
          <GuestLayout>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="*" element={<NotfoundPage />} />
            </Routes>
          </GuestLayout>
        )}
      </ThemeProvider>
    </ADCubeAdminContext.Provider>
  );
};
