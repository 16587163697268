import { PageContent } from "../../../components/PageContent";
import { useEffect, useState } from "react";
import { DateFilter } from "../../../components/filters/DateFilter";
import { DEFAULT_DATE } from "../../../common/const/DefaultDateRange";
import { ApiService } from "../../../service/ApiService";
import { CircularProgress } from "@mui/material";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { PageCard } from "../../../components/PageCard";
import { UserSelector } from "./components/UserSelector";
import MemoryIcon from "@mui/icons-material/Memory";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";

const FORM_STYLE = { minWidth: "100px" };

export const UserActivitiesPage = () => {
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingUserEvents, setLoadingUserEvents] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("url");
  const [selectedUser, setSelectedUSer] = useState(null);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [users, setUsers] = useState([]);
  const [activities, setActivities] = useState([]);
  const [dateFilterData, setDateFilterData] = useState(
    DEFAULT_DATE.USER_ACTIVITIES_RANGE
  );
  const loadUsers = () => {
    setLoadingUsers(true);
    ApiService.getUsers()
      .then((users) => {
        setUsers(users);
      })
      .catch(() => {
        console.log("ERRORE nel caricamento di organiation tree");
        setUsers(null);
      })
      .finally(() => {
        setLoadingUsers(false);
      });
  };

  const loadUserActivities = () => {
    setLoadingUserEvents(true);
    ApiService.getUserActivity(
      dateFilterData[0],
      dateFilterData[1],
      selectedUser,
      selectedMethod
    )
      .then((activities) => {
        setActivities(activities);
      })
      .catch(() => {
        console.log("ERRORE nel caricamento delle user activities");
        setActivities([]);
      })
      .finally(() => {
        setLoadingUserEvents(false);
      });
  };

  const loadAdvertiserActivities = () => {
    setLoadingUserEvents(true);
    ApiService.getAdvertiserActivity(
      dateFilterData[0],
      dateFilterData[1],
      selectedUser,
      selectedAdvertiser,
      selectedMethod
    )
      .then((activities) => {
        setActivities(activities);
      })
      .catch(() => {
        console.log("ERRORE nel caricamento delle user activities");
        setActivities([]);
      })
      .finally(() => {
        setLoadingUserEvents(false);
      });
  };

  const loadProjectActivities = () => {
    setLoadingUserEvents(true);
    ApiService.getProjectActivity(
      dateFilterData[0],
      dateFilterData[1],
      selectedUser,
      selectedAdvertiser,
      selectedProject,
      selectedMethod
    )
      .then((activities) => {
        setActivities(activities);
      })
      .catch(() => {
        console.log("ERRORE nel caricamento delle user activities");
        setActivities([]);
      })
      .finally(() => {
        setLoadingUserEvents(false);
      });
  };

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    if (selectedProject !== null) loadProjectActivities();
    else if (selectedAdvertiser !== null) loadAdvertiserActivities();
    else loadUserActivities();
  }, [selectedMethod, selectedUser, dateFilterData, selectedAdvertiser, selectedProject]);

  const filterUrl = (url) => {
    return url.replace("http://pre.api.adcube.ai/", "");
  };

  const getIcon = (method) => {
    let icon = <MemoryIcon />;
    if (method === "POST") icon = <LocalPostOfficeIcon />;
    return icon;
  };

  return (
    <PageContent>
      {loadingUsers ? (
        <CircularProgress />
      ) : (
        <PageCard title="User filter">
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            my={1}
          >
            <Box
              style={{
                display: "flex",
                gap: "2rem",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              flexGrow={1}
            >
              <FormControl sx={FORM_STYLE} size="small">
                <InputLabel id="company-label">Method</InputLabel>
                <Select
                  labelId="company-label"
                  id="company-select"
                  value={selectedMethod}
                  onChange={(e) => {
                    setSelectedMethod(e.target.value);
                  }}
                  label="Company"
                >
                  <MenuItem value="url">url</MenuItem>
                  <MenuItem value="action">action</MenuItem>
                </Select>
              </FormControl>
              <UserSelector
                style={{ flexGrow: "1" }}
                users={users}
                onAdvertiserChange={(selectedAdvertiser) =>
                  setSelectedAdvertiser(selectedAdvertiser)
                }
                onUserChange={(selectedUser) => {
                  setSelectedUSer(selectedUser);
                }}
                onProjectChange={(selectedProject) => {
                  setSelectedProject(selectedProject);
                }}
              />
            </Box>
            <Box>
              <DateFilter
                onDateChange={(dates) => {
                  setDateFilterData(dates);
                }}
                dateRange={DEFAULT_DATE.EVENTS_FILTER_RANGE_DATE}
                maximumDate={DEFAULT_DATE.EVENTS_FILTER_END_DATE.toDate()}
              />
            </Box>
          </Box>
        </PageCard>
      )}
      <PageCard title="User Activities">
        <Box gap="15px" display="flex" flexDirection="column" padding={2}>
          {selectedUser === null && selectedAdvertiser === null ? (
            "No user/advertiser selected"
          ) : loadingUserEvents ? (
            <CircularProgress />
          ) : !activities.length || activities.length === 0 ? (
            "No activities for such user"
          ) : (
            activities.map((a) => (
              <Box
                display="flex"
                alignItems="center"
                gap="20px"
                key={a.timestamp}
              >
                {getIcon(a.method)}
                <span>{a.timestamp}</span>
                <span style={{ width: "45px" }}>{a.method}</span>
                <span>
                  {selectedMethod === "url" ? filterUrl(a.url) : a.action}
                </span>
              </Box>
            ))
          )}
        </Box>
      </PageCard>
    </PageContent>
  );
};
