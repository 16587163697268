import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { DEFAULT_DATE } from "../../../../../common/const/DefaultDateRange";
import { DateFilter } from "../../../../../components/filters/DateFilter";
import { AnalyticsItemSelector } from "./AnalyticsItemSelector";
import { AnalyticsMetricsFilter } from "./AnalyticsMetricsFilter";

export const AnalyticChartFilter = ({
  companyList,
  onChangeFilter,
  onChangeMetrics,
}) => {
  const [entityFilterData, setEntityFilterData] = useState(null);
  const [dateFilterData, setDateFilterData] = useState(
    DEFAULT_DATE.ANALYTICS_FILTER_RANGE_FORMATTED
  );

  useEffect(() => {
    onChangeFilter(
      entityFilterData !== null
        ? {
            advertiserId: entityFilterData.advertiserId,
            projectId: entityFilterData.projectId,
            items: entityFilterData.itemList,
            startDate: dateFilterData[0],
            endDate: dateFilterData[1],
          }
        : null
    );
  }, [entityFilterData, dateFilterData, onChangeFilter]);

  return (
    <Box>
      <Box display="flex" alignItems="center" my={1}>
        <Box flexGrow={1}>
          <AnalyticsItemSelector
            companyList={companyList}
            onEntityChange={setEntityFilterData}
          />
        </Box>
        <Box>
          <DateFilter
            onDateChange={setDateFilterData}
            dateRange={DEFAULT_DATE.ANALYTICS_FILTER_RANGE_DATE}
            maximumDate={DEFAULT_DATE.ANALYTICS_FILTER_END_DATE.toDate()}
          />
        </Box>
      </Box>

      <Box my={1}>
        <AnalyticsMetricsFilter onChangeMetrics={onChangeMetrics} />
      </Box>
    </Box>
  );
};
