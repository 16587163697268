import { Box, Typography } from "@mui/material";
import moment from "moment";
import { JobErrorDisplay } from "./JobErrorDisplay";
import { JobStatusIcon } from "./JobStatusIcon";
import { JOB_STATUS } from "../../../../../common/const/JobStatus";

export const JobTreeNode = ({ jobNode, depth = 0 }) => {
  return (
    <Box p={1} pl={3 * depth} my={0} display="flex">
      <Box>
        <JobStatusIcon
          status={
            jobNode.step === "skipped" ? JOB_STATUS.SKIPPED : jobNode.status
          }
        />
      </Box>
      <Box flexGrow={1}>
        <Box display="flex" alignItems="" gap={3}>
          <Typography>
            <strong>{jobNode.keyParts[jobNode.keyParts.length - 1]}</strong>
          </Typography>
          <Box>
            <Box>
              <Typography fontSize="small">
                <em>Step: </em>
                {jobNode.step}
              </Typography>
              {jobNode.hasOwnProperty("item_last_download") ? (
                <Typography fontSize="small">
                  <em>Item last download: </em>
                  {jobNode.item_last_download}
                </Typography>
              ) : (
                ""
              )}
                            {jobNode.hasOwnProperty("item_name") ? (
                <Typography fontSize="small">
                  <em>Item name: </em>
                  {jobNode.item_name}
                </Typography>
              ) : (
                ""
              )}
              {jobNode.hasOwnProperty("item_last_fit") ? (
                <Typography fontSize="small">
                  <em>Item last fit: </em>
                  {jobNode.item_last_fit}
                </Typography>
              ) : (
                ""
              )}
              {jobNode.hasOwnProperty("id_on_platform") ? (
                <Typography fontSize="small">
                  <em>Id on platform: </em>
                  {jobNode.id_on_platform}
                </Typography>
              ) : (
                ""
              )}
              {jobNode.hasOwnProperty("item_id") ? (
                <Typography fontSize="small">
                  <em>Item id: </em>
                  {jobNode.item_id}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <Box display="flex" gap={1}>
              <Typography fontStyle="italic" fontSize="small">
                Start:{" "}
                {moment(jobNode.start_time * 1000).format(
                  "DD-MM-YYYY HH:mm:ss"
                )}
              </Typography>
              <Typography fontStyle="italic" fontSize="small">
                End:{" "}
                {jobNode.end_time
                  ? moment(jobNode.end_time * 1000).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )
                  : "-"}
              </Typography>
            </Box>
          </Box>
          <JobErrorDisplay jobData={jobNode} />
        </Box>

        {jobNode.children?.length > 0 ? (
          jobNode.children.map((c) => (
            <JobTreeNode
              key={c.keyParts[c.keyParts.length - 1]}
              jobNode={c}
              depth={depth + 1}
            />
          ))
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
