import { Box } from "@mui/material";
import { useState } from "react";
import { ALL_JOB_STATUS } from "../../../../../common/const/JobStatus";
import { JobStatusIcon } from "../jobs-tree/JobStatusIcon";

export const JobsStatusFilter = ({ onStatusChange }) => {
  const [selectedStatus, setSelectedStatus] = useState(ALL_JOB_STATUS);

  const toggleStatus = (status) => {
    const newSelectedStatusList = selectedStatus.includes(status)
      ? selectedStatus.filter((s) => s !== status)
      : [...selectedStatus, status];
    setSelectedStatus(newSelectedStatusList);
    onStatusChange(newSelectedStatusList);
  };

  return (
    <Box display="flex" gap={2}>
      {ALL_JOB_STATUS.map((s) => (
        <Box
          key={s}
          onClick={() => toggleStatus(s)}
          style={{ cursor: "pointer" }}
        >
          <JobStatusIcon
            status={s}
            style={!selectedStatus.includes(s) ? { color: "gray" } : null}
          />
        </Box>
      ))}
    </Box>
  );
};
