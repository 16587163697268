import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

export const BudgetOptProjectChart = ({ budgetOptdata }) => {

  const [markerPoints, setMarkerPoints] = useState([]);
  const [showBudgetsOnPlatform, setShowBudgetsOnPlatform] = useState(false);
  const [showBudOptBudgets, setShowBudOptBudgets] = useState(false);
  const [numberOfItems, setNumberOfItems] = useState(null);
  const [series, setSeries] = useState([]);

  const handleBudgetsOnPlatform = (event) => {
    setShowBudgetsOnPlatform(event.target.checked);
  };

  const handleBudOptBudgets = (event) => {
    setShowBudOptBudgets(event.target.checked);
  };

  const SLIDER_MAX_VALUE = Object.keys(budgetOptdata.budopt_curves).length
  const SLIDER_MIN_VALUE = 1



  let budgetsOnPlatform = {}
  let budoptBudgets = {}
  for (var key in budgetOptdata.budget_budopt) {
    budoptBudgets[key] = [budgetOptdata.budget_budopt[key], budgetOptdata.eval_budopt[key]]
    budgetsOnPlatform[key] = [budgetOptdata.budget_on_platform[key], budgetOptdata.eval_on_platform[key]]
  }

  const loadItems = (event, numberOfItemToLoad) => {
    let tmpSeries = []
    const item_names = budgetOptdata.item_names
    for (const [key, value] of Object.entries(budgetOptdata.budopt_curves)) {
      {
        if (tmpSeries.length < numberOfItemToLoad) {
          tmpSeries.push({
            "name": item_names[key],
            "data": value.map(value => [value[0], value[1].toFixed(2)])
          })
        } else break;
      }
    }
    setSeries(tmpSeries)
    setNumberOfItems(numberOfItemToLoad)
  }

  useEffect(() => {
    loadItems(null, SLIDER_MAX_VALUE)
  }, [])

  useEffect(() => {
    let budgetsOnPlatformPoints = []
    let budOptBudgetsPoints = []
    if (showBudgetsOnPlatform === true) {
      for (var budget of Object.values(budgetsOnPlatform)) {
        if (budgetsOnPlatformPoints.length < numberOfItems) {
          budgetsOnPlatformPoints.push({
            x: budget[0],
            y: budget[1],
            marker: {
              size: 8,
              fillColor: '#fff',
              strokeColor: 'red',
              radius: 2,
              cssClass: 'apexcharts-custom-class'
            }
          })
        } else break;
      }
    }

    if (showBudOptBudgets === true) {
      for (var budget of Object.values(budoptBudgets)) {
        if (budOptBudgetsPoints.length < numberOfItems) {
          budOptBudgetsPoints.push({
            x: budget[0],
            y: budget[1],
            marker: {
              size: 8,
              fillColor: '#fff',
              strokeColor: 'blue',
              radius: 2,
              cssClass: 'apexcharts-custom-class'
            }
          })
        } else break;
      }
    }

    setMarkerPoints([...budgetsOnPlatformPoints, ...budOptBudgetsPoints]);

  }, [showBudgetsOnPlatform, showBudOptBudgets, numberOfItems]);

  const options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      },
      foreColor: "#B4B7BD"
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    xaxis: {
      title: {
        text: budgetOptdata.x_label,
      },
      type: 'numeric',
    },
    yaxis: {
      title: {
        text: budgetOptdata.y_label,
      },
      type: 'numeric',
    },
    annotations: {
      points: markerPoints
    }
  }



  return (
    <>
      <ReactApexChart options={options} series={series} type="line" height={350} />
      <FormControlLabel control={<Checkbox />}
        label="show platform's budget"
        onChange={handleBudgetsOnPlatform} />
      <FormControlLabel control={<Checkbox />}
        label="show budopt's budget"
        onChange={handleBudOptBudgets} />
        <Typography id="input-slider" gutterBottom>
          Number of Items
        </Typography>
        <Slider
          defaultValue={SLIDER_MAX_VALUE}
          valueLabelDisplay="auto"
          step={1}
          marks
          min={SLIDER_MIN_VALUE}
          max={SLIDER_MAX_VALUE}
          onChange={loadItems}
        />
    </>
  );
};