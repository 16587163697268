import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { PageCard } from "../../../../components/PageCard";
import { ApiService } from "../../../../service/ApiService";
import { DeleteNoCompanyAdvertisersBox } from "./components/DeleteNoCompanyAdvertisersBox";

export const NoCompanyAdvertisers = () => {
  const [loading, setLoading] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);

  const loadNoCompanyAdvertisers = () => {
    setLoading(true);
    ApiService.getNoCompanyAdvertisers()
      .then((advertisers) => {
        setAdvertisers(advertisers);
      })
      .catch(() => {
        setAdvertisers([]);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadNoCompanyAdvertisers();
  }, []);

  return (
    <PageCard title="Advertiser with no company">
      {loading ? (
        <CircularProgress />
      ) : advertisers.length === 0 ? (
        <Typography>Everything ok, nothing to do.</Typography>
      ) : (
        <Box>
          <List>
            {advertisers.map((advertiser) => (
              <ListItem key={advertiser.name}>
                <ListItemButton>{advertiser.name}</ListItemButton>
              </ListItem>
            ))}
          </List>
          <DeleteNoCompanyAdvertisersBox onDelete={loadNoCompanyAdvertisers} />
        </Box>
      )}
    </PageCard>
  );
};
