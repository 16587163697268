import { Box, Grid } from "@mui/material";
import AreaChart from "../../../../../common/google-charts/AreaChart";
import LineChart from "../../../../../common/google-charts/LineChart";

const round2 = (num) => +(Math.round(num + "e+2") + "e-2");

export const SpendoptChart = ({
  projectOverview,
  spendoptData,
}) => {
  const targets = {
    budget: {
      title: "Budget",
      prefix: true,
    },
    impressions: {
      title: "Impressions",
      prefix: false,
      metricKey: "CPM",
      metricFunc: (v, b) => round2(b / (v * 1000)),
      valueFunc: (v) => v,
    },
    clicks: {
      title: "Clicks",
      prefix: false,
      metricKey: "CPC",
      metricFunc: (v, b) => round2(b / v),
      valueFunc: (v) => v,
    },
    conversions: {
      title: "Conversions",
      prefix: false,
      metricKey: "CPA",
      metricFunc: (v, b) => round2(b / v),
      valueFunc: (v) => v,
    },
    conversionValues: {
      title: `Conversions`,
      prefix: true,
      metricKey: "CPA",
      metricFunc: (v, b) => round2((v * projectOverview.conversionValue) / b),
      valueFunc: (v) => v * projectOverview.conversionValue,
    },
    revenue: {
      title: "Revenue",
      prefix: true,
      metricKey: "ROI",
      metricFunc: (v, b) => round2(v / b),
      valueFunc: (v) => v,
    },
  };
  let target = targets[projectOverview.goal];

  if (target === targets.conversions && projectOverview.useConversionValue) {
    target = targets.conversionValues;
  }

  // Initialize the data for the series
  const featureList = spendoptData.feature_list;

  // Unconstrained
  const uTargetLowerList = spendoptData.unconstrained_target_list
    .map((v) => v.lower)
    .map((v) => target.valueFunc(v));
  const uTargetList = spendoptData.unconstrained_target_list
    .map((v) => v.median)
    .map((v) => target.valueFunc(v));
  const uTargetUpperList = spendoptData.unconstrained_target_list
    .map((v) => v.upper)
    .map((v) => target.valueFunc(v));

  // Constrained
  const cTargetLowerList = spendoptData.constrained_target_list
    .map((v) => v.lower)
    .map((v) => target.valueFunc(v));
  const cTargetList = spendoptData.constrained_target_list
    .map((v) => v.median)
    .map((v) => target.valueFunc(v));
  const cTargetUpperList = spendoptData.constrained_target_list
    .map((v) => v.upper)
    .map((v) => target.valueFunc(v));

  const options = {
    currencySuffix: projectOverview.currency,
    intervals: true,
    xAxis: {
      label: "Budget",
      data: featureList,
      isCurrency: true,
      currency: projectOverview.currency,
    },
    yAxis: {
      labels: ["Full AI", "Custom"],
      data: [uTargetList, cTargetList],
      lowerBounds: [uTargetLowerList, cTargetLowerList],
      upperBounds: [uTargetUpperList, cTargetUpperList],
      isCurrency: target.prefix,
      currency: projectOverview.currency,
    },
  };
  return (
    <Box p={1.5}>
      <Grid container>
        <Grid item xs={6}>
          <LineChart options={options} />
        </Grid>
        <Grid item xs={6}>
          <AreaChart options={options} />
        </Grid>
      </Grid>
    </Box>
  );
};
