import { Box } from "@mui/material";
import { PageCard } from "../../../../components/PageCard";

export const ErrorBox = () => {
  return (
    <PageCard title="Events">
      <Box display="flex" alignItems="center" my={1}>
        <Box flexGrow={1}> Qualcosa è andato storto</Box>
      </Box>
    </PageCard>
  );
};
