import { Box } from "@mui/material";
import { useContext, useState } from "react";
import Select from "react-select";
import { METRICS_TYPE } from "../../../../../common/const/Metrics";
import { ADCubeAdminContext } from "../../../../../context/ADCubeAdminContext";

const METRICS_OPTION_LIST = [
  { value: METRICS_TYPE.COST, label: "Cost", isFixed: false },
  { value: METRICS_TYPE.IMPRESSIONS, label: "Impressions", isFixed: false },
  { value: METRICS_TYPE.CLICKS, label: "Clicks", isFixed: false },
  { value: METRICS_TYPE.CONVERSIONS, label: "Conversions", isFixed: false },
  { value: METRICS_TYPE.REVENUE, label: "Revenue", isFixed: false },
  { value: METRICS_TYPE.PAGE_VIEW, label: "GA Conversions", isFixed: false },
  { value: METRICS_TYPE.CPM, label: "CPM", isFixed: false },
  { value: METRICS_TYPE.CPC, label: "CPC", isFixed: false },
  { value: METRICS_TYPE.CPA, label: "CPA", isFixed: false },
  { value: METRICS_TYPE.ROAS, label: "ROAS", isFixed: false },
];

export const AnalyticsMetricsFilter = ({ onChangeMetrics }) => {
  const adCubeAdminContext = useContext(ADCubeAdminContext);

  const [selectedMetrics, setSelectedMetrics] = useState([
    METRICS_OPTION_LIST[0],
  ]);

  const onSelectChange = (metricsList) => {
    if (metricsList.length > 0) {
      setSelectedMetrics(metricsList);
      onChangeMetrics(metricsList.map((m) => m.value));
    }
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Box minWidth="50%">
        <Select
          isClearable={false}
          value={selectedMetrics}
          isMulti
          name="metrics"
          options={METRICS_OPTION_LIST}
          onChange={onSelectChange}
          className={
            adCubeAdminContext.themeLight ? "" : "react-select-container"
          }
          classNamePrefix={adCubeAdminContext.themeLight ? "" : "react-select"}
        />
      </Box>
    </Box>
  );
};
