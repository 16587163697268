import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { ADCUBE_ENTITY } from "../../../../common/const/ADcubeEntity";
import { PageCard } from "../../../../components/PageCard";
import { UsersCompany } from "./UserCompany";
import { UserTree } from "./UserTree";

export const UserCard = ({ users, entitities, companyList }) => {
  const [selectedEntity, setSelectedEntity] = useState(null);
    
  return (
    <PageCard title="Users">
      <Grid container marginTop={1}>
        <Grid item xs={6}>
          <UserTree
            users={users}
            entitities={[
              ADCUBE_ENTITY.COMPANY,
              ADCUBE_ENTITY.ADVERTISER,
              ADCUBE_ENTITY.PROJECT,
              ADCUBE_ENTITY.USER
            ]}
            companyList={companyList}
            onEntityClick={setSelectedEntity}
          />
        </Grid>
        <Grid item xs={6}>
          {selectedEntity ? (
            <UsersCompany
              selectedEntity={selectedEntity}
              companyList={companyList}
            />
          ) : (
            <Typography>Select an entity</Typography>
          )}
        </Grid>
      </Grid>
    </PageCard>
  );
};
