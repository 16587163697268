import { Box, Paper } from "@mui/material";

export const WidgetCard = ({ children }) => {
  return (
    <Box>
      <Paper border={1} elevation={10}>
        <Box px={2} pb={1} pt={1}>
          {children}
        </Box>
      </Paper>
    </Box>
  );
};
