import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AnalyticsUtils } from "../../../../../common/utils/AnalyticsUtils";
import { ApiService } from "../../../../../service/ApiService";
import { AnalyticsChart } from "./AnalyticsChart";

export const AnalyticsChartContainer = ({ filterData, metricsData }) => {
  const [chartExpanded, setChartExpanded] = useState(false);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (filterData !== null) {
      setChartData(null);
      ApiService.getAnalyticsChartData(
        filterData.projectId,
        filterData.items,
        filterData.startDate,
        filterData.endDate
      )
        .then((response) => {
          try {
            setChartData(AnalyticsUtils.enrichMetrics(response));
          } catch (error) {
            console.log(
              "Errore nel calcolare i valori delle metriche: ",
              error
            );
            setChartData(null);
          }
        })
        .catch(() => {
          setChartData(null);
        });
    } else {
      setChartData(null);
    }
  }, [filterData]);

  return (
    <Box>
      <Box display="flex">
        <Box flexGrow={1}></Box>
        <Box>
          <Button onClick={() => setChartExpanded(!chartExpanded)}>
            {chartExpanded ? "COMPRIMI" : "ESPANDI"}
          </Button>
        </Box>
      </Box>
      <Box
        p={1.5}
        maxHeight={chartExpanded ? "unset" : 415}
        style={
          chartExpanded ? {} : { overflowX: "hidden", overflowY: "scroll" }
        }
      >
        {filterData ? (
          chartData ? (
            <Grid container spacing={2}>
              {metricsData.map((m, i, a) => (
                <Grid item key={m} xs={6}>
                  <AnalyticsChart chartData={chartData} metric={m} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <CircularProgress />
          )
        ) : (
          <Typography>Seleziona delle entity per vedere i grafici</Typography>
        )}
      </Box>
    </Box>
  );
};
