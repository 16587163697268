import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { OrganizationTreeUtils } from "../../../../components/organization-tree/OrganizationTreeUtils";
import { ApiService } from "../../../../service/ApiService";

const SERVER_STATUS = {
  OK: "ok",
  KO: "ko",
  WARNING: "warning",
};

export const AdvertiserNotInAdminCompanyWidget = () => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    ApiService.getOrganizationTree()
      .then((responseCompanyList) => {
        const companyList =
          OrganizationTreeUtils.enrichCompanyListData(responseCompanyList);
        const ok = companyList.every(
          (c) => !c.warningAdvertiserNotInAdminCompany
        );
        setStatus(ok ? SERVER_STATUS.OK : SERVER_STATUS.WARNING);
      })
      .catch(() => {
        setStatus(SERVER_STATUS.KO);
      });
  }, []);

  return (
    <Box display="flex" gap={1} p={2}>
      <Typography flexGrow={1}>Advertiser not in admin company</Typography>
      {status === SERVER_STATUS.OK ? (
        <Typography color="success.main">OK</Typography>
      ) : status === SERVER_STATUS.KO ? (
        <Typography color="error.main">KO</Typography>
      ) : status === SERVER_STATUS.WARNING ? (
        <Typography color="warning.main">WARNING</Typography>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
