import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { TreeItem, TreeView } from "@mui/lab";
import { Button, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { ADCUBE_ENTITY } from "../../../../common/const/ADcubeEntity";
import { OrganizationTreeUtils } from "../../../../components/organization-tree/OrganizationTreeUtils";
import { TreeItemLabel } from "../../../../components/organization-tree/TreeItemLabel";

export const UserTree = ({
  users,
  entitities,
  companyList,
  onEntityClick = () => {},
}) => {
  const [companies, setCompanies] = useState([]);
  const [allNodeList, setAllNodeList] = useState([]);
  const [expandedList, setExpandedList] = useState([]);

  const nodeToggle = (_event, nodeList) => {
    setExpandedList(nodeList);
  };
  const expandAllNode = () => {
    setExpandedList(allNodeList);
  };
  const expandAllAdvertisers = () => {
    setExpandedList(OrganizationTreeUtils.filterCompanyNodeList(allNodeList));
  };
  const expandAllProjects = () => {
    setExpandedList(
      OrganizationTreeUtils.filterAdvertiserNodeList(allNodeList)
    );
  };
  const collapseAllNode = () => {
    setExpandedList([]);
  };

  return (
    <>
      <Box>
        {entitities.includes(ADCUBE_ENTITY.ADVERTISER) ? (
          <Button onClick={expandAllAdvertisers}>All Advertisers</Button>
        ) : (
          <></>
        )}
        {entitities.includes(ADCUBE_ENTITY.PROJECT) ? (
          <Button onClick={expandAllProjects}>All Projects</Button>
        ) : (
          <></>
        )}
      </Box>
      <Box>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expandedList}
          onNodeToggle={nodeToggle}
        >
          {entitities.includes(ADCUBE_ENTITY.COMPANY) ? (
            users.map((user) => (
              <TreeItem
                key={OrganizationTreeUtils.buildNodeId(user.user_id)}
                nodeId={OrganizationTreeUtils.buildNodeId(user.user_id)}
                label={
                  <TreeItemLabel
                    entityType={ADCUBE_ENTITY.USER}
                    labelText={
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography>{user.name}</Typography>
                        {user.company_id === null ? (
                          <Tooltip title={"User not in any company"}>
                            <PriorityHighIcon color="warning"></PriorityHighIcon>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </Box>
                    }
                    labelInfo={user.company_id !== null ? 1 : 0}
                  />
                }
                onClick={() =>
                  onEntityClick({
                    type: ADCUBE_ENTITY.USER,
                    ...user,
                  })
                }
              >
                {user.company_id !== null ? (
                  <TreeItem
                    key={OrganizationTreeUtils.buildNodeId(
                      user.user_id,
                      user.company_id
                    )}
                    nodeId={OrganizationTreeUtils.buildNodeId(
                      user.user_id,
                      user.company_id
                    )}
                    label={
                      <TreeItemLabel
                        entityType={ADCUBE_ENTITY.COMPANY}
                        labelText={
                          <Box display="flex" alignItems="center" gap={1}>
                            <Typography>{user.name}</Typography>
                            {user.advertisers.length === 0 ? (
                              <Tooltip title={"The company has no advertisers"}>
                                <PriorityHighIcon color="warning"></PriorityHighIcon>
                              </Tooltip>
                            ) : (
                              <></>
                            )}
                          </Box>
                        }
                        labelInfo={
                          entitities.includes(ADCUBE_ENTITY.COMPANY)
                            ? user.advertisers.length
                            : 0
                        }
                      />
                    }
                    onClick={() =>
                      onEntityClick({
                        type: ADCUBE_ENTITY.COMPANY,
                        ...user,
                      })
                    }
                  >
                    {entitities.includes(ADCUBE_ENTITY.ADVERTISER) &&
                    user.advertisers.length > 0
                      ? user.advertisers.map((advertiser) => (
                          <TreeItem
                            key={OrganizationTreeUtils.buildNodeId(
                              user.user_id,
                              user.company_id,
                              advertiser.advertiser_id
                            )}
                            nodeId={OrganizationTreeUtils.buildNodeId(
                              user.user_id,
                              user.company_id,
                              advertiser.advertiser_id
                            )}
                            label={
                              <TreeItemLabel
                                entityType={ADCUBE_ENTITY.ADVERTISER}
                                labelText={
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <Typography>{advertiser.name}</Typography>
                                    {advertiser.projects.length === 0 ? (
                                      <Tooltip
                                        title={"Advertiser not in any project"}
                                      >
                                        <PriorityHighIcon color="warning"></PriorityHighIcon>
                                      </Tooltip>
                                    ) : (
                                      <></>
                                    )}
                                  </Box>
                                }
                                labelInfo={
                                  entitities.includes(ADCUBE_ENTITY.PROJECT)
                                    ? advertiser.projects.length
                                    : 0
                                }
                              />
                            }
                            onClick={() =>
                              onEntityClick({
                                type: ADCUBE_ENTITY.ADVERTISER,
                                user_id: user.user_id,
                                ...advertiser,
                              })
                            }
                          >
                            {/*                             {entitities.includes(ADCUBE_ENTITY.PROJECT)
                              ? advertiser.projects.map((project) => (
                                  <TreeItem
                                    key={project.project_id}
                                    nodeId={project.project_id}
                                    label={
                                      <TreeItemLabel
                                        entityType={ADCUBE_ENTITY.PROJECT}
                                        labelText={project.name}
                                        labelInfo={
                                          entitities.includes(
                                            ADCUBE_ENTITY.ITEM
                                          )
                                            ? project.items.length
                                            : 0
                                        }
                                      />
                                    }
                                    onClick={() =>
                                      onEntityClick({
                                        type: ADCUBE_ENTITY.PROJECT,
                                        ...project,
                                      })
                                    }
                                  ></TreeItem>
                                ))
                              : ""} */}
                          </TreeItem>
                        ))
                      : ""}
                  </TreeItem>
                ) : (
                  ""
                )}
              </TreeItem>
            ))
          ) : (
            <></>
          )}
        </TreeView>
      </Box>
    </>
  );
};
