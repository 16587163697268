import { ALL_METRICS_LIST, METRICS_TYPE } from "../const/Metrics";

const round2 = (num) => +(Math.round(num + "e+2") + "e-2");

const METRICS_ELABORATION = {
  [METRICS_TYPE.COST]: (v) => v.cost,
  [METRICS_TYPE.IMPRESSIONS]: (v) => v.impressions,
  [METRICS_TYPE.CLICKS]: (v) => v.clicks,
  [METRICS_TYPE.CONVERSIONS]: (v) => v.conversions,
  [METRICS_TYPE.REVENUE]: (v) => v.revenue,
  [METRICS_TYPE.PAGE_VIEW]: (v) => 0,
  [METRICS_TYPE.CPM]: (v) =>
    v.cost === 0 ? 0 : (v.cost / v.impressions) * 1000,
  [METRICS_TYPE.CPC]: (v) => (v.cost === 0 ? 0 : (v.cost / v.clicks) * 1000),
  [METRICS_TYPE.CPA]: (v) =>
    v.cost === 0 ? 0 : (v.cost / v.conversions) * 1000,
  [METRICS_TYPE.ROAS]: (v) => (v.cost === 0 ? 0 : (v.cost / v.revenue) * 1000),
};
console.log("AAAAAAAAAAAAAA check PAGE_VIEW_METRICS CALCULATION!!!!");

const enrichMetrics = (analyticsData) => {
  Object.values(analyticsData).forEach((metricsData) => {
    ALL_METRICS_LIST.forEach((k) => {
      metricsData[k] = round2(METRICS_ELABORATION[k](metricsData));
    });
  });
  return analyticsData;
};

export const AnalyticsUtils = {
  enrichMetrics,
};
