import moment from "moment";

const now = moment();
const oneDayAgo = moment().subtract(1, "day");
const oneWeekAgo = moment().subtract(1, "week");
const oneMonthAgo = moment().subtract(1, "month");
const oneDayAndOneMonthAgo = moment().subtract(1, "day").subtract(1, "month");

const analyticsDateRange = [oneDayAndOneMonthAgo, oneDayAgo];
const eventsDateRange = [oneWeekAgo, now];
const userActivitiesRange = [oneWeekAgo, now];
const overviewRange = [oneMonthAgo, now]

export const DEFAULT_DATE = {
  JOB_FILTER_START_DATE: oneMonthAgo,
  JOB_FILTER_END_DATE: now,
  JOB_FILTER_DATE_RANGE: [oneMonthAgo, now].map((d) =>
    moment(d).format("YYYY-MM-DD")
  ),
  ANALYTICS_FILTER_START_DATE: oneDayAndOneMonthAgo,
  ANALYTICS_FILTER_END_DATE: oneDayAgo,
  ANALYTICS_FILTER_RANGE_DATE: analyticsDateRange.map((d) => d.toDate()),
  ANALYTICS_FILTER_RANGE_FORMATTED: analyticsDateRange.map((d) =>
    moment(d).format("YYYY-MM-DD")
  ),
  EVENTS_FILTER_RANGE_DATE: eventsDateRange.map((d) => d.toDate()),
  EVENTS_FILTER_END_DATE: now,
  USER_ACTIVITIES_RANGE: userActivitiesRange.map((d) =>
    moment(d).format("YYYY-MM-DD")),
  OVERVIEW_RANGE: overviewRange.map((d) =>
  moment(d).format("YYYY-MM-DD"))
};
