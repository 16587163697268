import { Box } from "@mui/material";
import { PageCard } from "../../../../components/PageCard";
import Chart from "react-apexcharts";

export const EventBox = ({
  projectName,
  date,
  eventType,
  projectItems,
  campaignNameList,
}) => {
  const options = {
    labels: campaignNameList,
    legend: {
      width: 420,
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },
    chart: {
      height: 100,
    },
  };
  return (
    <PageCard>
      <Box>
        <Box display="flex" alignItems="center" my={1}>
          <Box flexGrow={1}>{projectName}</Box>
          <Box>{date}</Box>
        </Box>

        <Box>{eventType}</Box>
        <Chart
          options={options}
          series={projectItems}
          type="donut"
          width="600"
        />
      </Box>
    </PageCard>
  );
};
