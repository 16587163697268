import { CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ADCUBE_ENTITY } from "../../../common/const/ADcubeEntity";
import { OrganizationTree } from "../../../components/organization-tree/OrganizationTree";
import { PageCard } from "../../../components/PageCard";
import { PageContent } from "../../../components/PageContent";
import { ApiService } from "../../../service/ApiService";
import { UsersPermission } from "./components/UsersPermission";
import { UserTree } from "./components/UserTree";
import { UserCard } from "./components/UserCard";

export const UsersPage = () => {
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [users, setUsers] = useState([]);

  const loadUsers = () => {
    setLoadingUsers(true);
    ApiService.getUsers()
      .then((users) => {
        setUsers(users);
      })
      .catch()
      .finally(() => {
        setLoadingUsers(false);
      });
  };

  const loadTree = () => {
    setSelectedEntity(null);
    setLoading(true);

    ApiService.getOrganizationTree()
      .then((responseCompanyList) => {
        setCompanyList(responseCompanyList);
      })
      .catch()
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadTree();
    loadUsers();
  }, []);

  return (
    <PageContent>
      {loading ? (
        <CircularProgress />
      ) : (
        <PageCard title="Entity Tree">
          <Grid container marginTop={1}>
            <Grid item xs={6}>
              <OrganizationTree
                companyList={companyList}
                entitities={[
                  ADCUBE_ENTITY.COMPANY,
                  ADCUBE_ENTITY.ADVERTISER,
                  ADCUBE_ENTITY.PROJECT,
                ]}
                onEntityClick={setSelectedEntity}
              />
            </Grid>
            <Grid item xs={6}>
              {selectedEntity ? (
                <UsersPermission
                  selectedEntity={selectedEntity}
                  key={selectedEntity.type + "_" + selectedEntity.id}
                />
              ) : (
                <Typography>Select an entity to show users</Typography>
              )}
            </Grid>
          </Grid>
        </PageCard>
      )}
      {loadingUsers ? (
        <CircularProgress />
      ) : (
        <UserCard users={users} companyList={companyList}></UserCard>
      )}
    </PageContent>
  );
};
