
const getCampaignNames = (projectId, companyList) => {
    var campaignNameList = [];
    for (const company of companyList) {
      for (const advertiser of company["advertisers"]) {
        for (const project of advertiser["projects"]) {
          if (project["project_id"] === projectId)
            for (const item of project["items"])
              campaignNameList.push(item["item_data"]["name"]);
        }
      }
    }
    return campaignNameList;
  };
  
  const getProjectName = (projectId, companyList) => {
    var projectName = "";
    for (const company of companyList) {
      for (const advertiser of company["advertisers"]) {
        for (const project of advertiser["projects"]) {
          if (project["project_id"] === projectId)
            projectName = project["name"];
            //brrekare nel modo giusto
        }
      }
    }
    return projectName;
  };
  const getEventsBudget = (event) => {
    var eventsBudget = [];
    for (const item of event["budget"]) {
      eventsBudget.push(item["allocated_budget"]);
    }
    return eventsBudget;
  };

export const EventsUtils = {
    getCampaignNames,
    getProjectName,
    getEventsBudget
}