import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { TreeItem, TreeView } from "@mui/lab";
import { Button, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { ADCUBE_ENTITY } from "../../common/const/ADcubeEntity";
import { OrganizationTreeUtils } from "./OrganizationTreeUtils";
import { TreeItemLabel } from "./TreeItemLabel";

export const OrganizationTree = ({
  companyList,
  entitities,
  onEntityClick = () => {},
}) => {
  const [companies, setCompanies] = useState([]);
  const [allNodeList, setAllNodeList] = useState([]);
  const [expandedList, setExpandedList] = useState([]);

  useEffect(() => {
    setAllNodeList(OrganizationTreeUtils.buildAllNodeList(companyList));
    setCompanies(OrganizationTreeUtils.enrichCompanyListData(companyList));
  }, [companyList]);

  const nodeToggle = (_event, nodeList) => {
    setExpandedList(nodeList);
  };
  const expandAllNode = () => {
    setExpandedList(allNodeList);
  };
  const expandAllAdvertisers = () => {
    setExpandedList(OrganizationTreeUtils.filterCompanyNodeList(allNodeList));
  };
  const expandAllProjects = () => {
    setExpandedList(
      OrganizationTreeUtils.filterAdvertiserNodeList(allNodeList)
    );
  };
  const collapseAllNode = () => {
    setExpandedList([]);
  };

  return (
    <>
      <Box>
        {entitities.includes(ADCUBE_ENTITY.ADVERTISER) ? (
          <Button onClick={expandAllAdvertisers}>All Advertisers</Button>
        ) : (
          <></>
        )}
        {entitities.includes(ADCUBE_ENTITY.PROJECT) ? (
          <Button onClick={expandAllProjects}>All Projects</Button>
        ) : (
          <></>
        )}

        {entitities.includes(ADCUBE_ENTITY.ITEM) ? (
          <Button onClick={expandAllNode}>All Items</Button>
        ) : (
          <></>
        )}
        <Button onClick={collapseAllNode}>Collapse All</Button>
      </Box>
      <Box>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expandedList}
          onNodeToggle={nodeToggle}
        >
          {entitities.includes(ADCUBE_ENTITY.COMPANY) ? (
            companies.map((company) => (
              <TreeItem
                key={OrganizationTreeUtils.buildNodeId(company.company_id)}
                nodeId={OrganizationTreeUtils.buildNodeId(company.company_id)}
                label={
                  <TreeItemLabel
                    entityType={ADCUBE_ENTITY.COMPANY}
                    labelText={
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography>{company.name}</Typography>
                        {company.warningAdvertiserNotInAdminCompany ? (
                          <Tooltip
                            title={"Check Advertisers not in Admin Company"}
                          >
                            <PriorityHighIcon color="warning"></PriorityHighIcon>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </Box>
                    }
                    labelInfo={
                      entitities.includes(ADCUBE_ENTITY.ADVERTISER)
                        ? company.advertisers.length
                        : 0
                    }
                  />
                }
                onClick={() =>
                  onEntityClick({
                    type: ADCUBE_ENTITY.COMPANY,
                    id: company.company_id,
                    name: company.name,
                  })
                }
              >
                {entitities.includes(ADCUBE_ENTITY.ADVERTISER)
                  ? company.advertisers.map((advertiser) => (
                      <TreeItem
                        key={OrganizationTreeUtils.buildNodeId(
                          company.company_id,
                          advertiser.advertiser_id
                        )}
                        nodeId={OrganizationTreeUtils.buildNodeId(
                          company.company_id,
                          advertiser.advertiser_id
                        )}
                        label={
                          <TreeItemLabel
                            entityType={ADCUBE_ENTITY.ADVERTISER}
                            labelText={
                              <Box display="flex" alignItems="center" gap={1}>
                                <Typography>{advertiser.name}</Typography>
                                {advertiser.warningNotInAdminCompany ? (
                                  <Tooltip
                                    title={"Advertiser not in Admin Company"}
                                  >
                                    <PriorityHighIcon color="warning"></PriorityHighIcon>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                              </Box>
                            }
                            labelInfo={
                              entitities.includes(ADCUBE_ENTITY.PROJECT)
                                ? advertiser.projects.length
                                : 0
                            }
                          />
                        }
                        onClick={() =>
                          onEntityClick({
                            type: ADCUBE_ENTITY.ADVERTISER,
                            id: advertiser.advertiser_id,
                            name: advertiser.name,
                            company_id: company.company_id,
                          })
                        }
                      >
                        {entitities.includes(ADCUBE_ENTITY.PROJECT)
                          ? advertiser.projects.map((project) => (
                              <TreeItem
                                key={OrganizationTreeUtils.buildNodeId(
                                  company.company_id,
                                  advertiser.advertiser_id,
                                  project.project_id
                                )}
                                nodeId={OrganizationTreeUtils.buildNodeId(
                                  company.company_id,
                                  advertiser.advertiser_id,
                                  project.project_id
                                )}
                                label={
                                  <TreeItemLabel
                                    entityType={ADCUBE_ENTITY.PROJECT}
                                    labelText={project.name}
                                    labelInfo={
                                      entitities.includes(ADCUBE_ENTITY.ITEM)
                                        ? project.items.length
                                        : 0
                                    }
                                  />
                                }
                                onClick={() =>
                                  onEntityClick({
                                    type: ADCUBE_ENTITY.PROJECT,
                                    id: project.project_id,
                                    name: project.name,
                                    company_id: company.company_id,
                                    advertiser_id: advertiser.advertiser_id,
                                  })
                                }
                              >
                                {entitities.includes(ADCUBE_ENTITY.ITEM)
                                  ? project.items.map((item) => (
                                      <TreeItem
                                        key={OrganizationTreeUtils.buildNodeId(
                                          company.company_id,
                                          advertiser.advertiser_id,
                                          project.project_id,
                                          item.item_data.item_id
                                        )}
                                        nodeId={OrganizationTreeUtils.buildNodeId(
                                          company.company_id,
                                          advertiser.advertiser_id,
                                          project.project_id,
                                          item.item_data.item_id
                                        )}
                                        label={
                                          <TreeItemLabel
                                            entityType={
                                              item.type === "campaign"
                                                ? ADCUBE_ENTITY.CAMPAIGN
                                                : ADCUBE_ENTITY.ADGROUP
                                            }
                                            labelText={item.item_data.name}
                                          />
                                        }
                                        onClick={() =>
                                          onEntityClick({
                                            type: ADCUBE_ENTITY.ITEM,
                                            id: item.item_id,
                                            name: item.item_data.name,
                                            company_id: company.company_id,
                                            advertiser_id:
                                              advertiser.advertiser_id,
                                            project_id: project.project_id,
                                          })
                                        }
                                      ></TreeItem>
                                    ))
                                  : ""}
                              </TreeItem>
                            ))
                          : ""}
                      </TreeItem>
                    ))
                  : ""}
              </TreeItem>
            ))
          ) : (
            <></>
          )}
        </TreeView>
      </Box>
    </>
  );
};
