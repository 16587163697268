import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ApiService } from "../../../../service/ApiService";

const SERVER_STATUS = {
  OK: "ok",
  KO: "ko",
};

export const PypiServerStatusWidget = () => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    ApiService.pypiServerCheck()
      .then(() => {
        setStatus(SERVER_STATUS.OK);
      })
      .catch(() => {
        setStatus(SERVER_STATUS.KO);
      });
  }, []);

  return (
    <Box display="flex" gap={1} p={2}>
      <Typography flexGrow={1}>Pypi server online</Typography>
      {status === SERVER_STATUS.OK ? (
        <Typography color="success.main">OK</Typography>
      ) : status === SERVER_STATUS.KO ? (
        <Typography color="error.main">KO</Typography>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
