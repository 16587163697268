import { Grid } from "@mui/material";
import { PageCard } from "../../../components/PageCard";
import { PageContent } from "../../../components/PageContent";
import { AdvertiserNotInAdminCompanyWidget } from "./companents/AdvertiserNotInAdminCompanyWidget";
import { NoCompanyAdvertiserWidget } from "./companents/NoCompanyAdvertiserWidget";
import { PypiServerStatusWidget } from "./companents/PyPiServerStatusWidget";
import { SecretServerStatusWidget } from "./companents/SecretServerStatusWidget";
import { WidgetCard } from "./companents/WidgetCard";

const WIDGETS = [
  SecretServerStatusWidget,
  PypiServerStatusWidget,
  AdvertiserNotInAdminCompanyWidget,
  NoCompanyAdvertiserWidget,
];

export const HomePage = () => {
  return (
    <PageContent>
      <PageCard title="Status">
        <Grid container spacing={2} py={1}>
          {WIDGETS.map((WIDGET, index) => (
            <Grid item xs={6} key={index}>
              <WidgetCard>
                <WIDGET />
              </WidgetCard>
            </Grid>
          ))}
        </Grid>
      </PageCard>
    </PageContent>
  );
};
