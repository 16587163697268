import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const NotfoundPage = () => {
  let navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate]);
  return <div>NOT FOUND</div>;
};
