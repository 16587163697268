import { useState } from "react";
import { DEFAULT_DATE } from "../../../common/const/DefaultDateRange";
import { ALL_JOB_STATUS } from "../../../common/const/JobStatus";
import { PageCard } from "../../../components/PageCard";
import { PageContent } from "../../../components/PageContent";
import { JobsFilter } from "./components/filters/JobsFilter";
import { JobsContainer } from "./components/jobs-tree/JobsContainer";

export const JobsPage = () => {
  const [dateRangeFilter, setDateRangeFilter] = useState(
    DEFAULT_DATE.JOB_FILTER_DATE_RANGE
  );
  const [jobStatusFilter, setJobStatusFilter] = useState(ALL_JOB_STATUS);

  return (
    <PageContent>
      <PageCard title="Jobs filter">
        <JobsFilter
          onChangeDateFilter={setDateRangeFilter}
          onChangeStatusFilter={setJobStatusFilter}
        />
      </PageCard>
      <PageCard title="Jobs">
        <JobsContainer
          dateRangeFilter={dateRangeFilter}
          jobStatusFilter={jobStatusFilter}
        />
      </PageCard>
    </PageContent>
  );
};
