import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import ErrorIcon from "@mui/icons-material/Error";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import { SkipNextSharp } from "@mui/icons-material";
import { JOB_STATUS } from "./JobStatus";

export const JOB_STATUS_ICON_CONFIG = {
  [JOB_STATUS.IDLE]: {
    tooltipTitle: "Idle",
    componentIcon: HourglassEmptyIcon,
    color: "info",
  },
  [JOB_STATUS.STARTING]: {
    tooltipTitle: "Starting",
    componentIcon: PlayArrowIcon,
    color: "warning",
  },
  [JOB_STATUS.RUNNING]: {
    tooltipTitle: "Running",
    componentIcon: DirectionsRunIcon,
    color: "warning",
  },
  [JOB_STATUS.COMPLETED]: {
    tooltipTitle: "Completed",
    componentIcon: SportsScoreIcon,
    color: "success",
  },
  [JOB_STATUS.ERROR]: {
    tooltipTitle: "Error",
    componentIcon: ErrorIcon,
    color: "error",
  },
  [JOB_STATUS.SKIPPED]: {
    tooltipTitle: "Skipped",
    componentIcon: SkipNextSharp,
    color: "warning",
  },
};
