import { Box } from "@mui/material";
import * as React from "react";
import { ADCUBE_ENTITY_ICON } from "../../common/const/ADcubeEntityIcon";

export const TreeItemLabel = ({ entityType, labelText, labelInfo }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
      <Box color="inherit" sx={{ mr: 1 }}>
        {ADCUBE_ENTITY_ICON[entityType]}
      </Box>
      <Box flexGrow={1}>{labelText}</Box>
      <Box>{labelInfo}</Box>
    </Box>
  );
};
