import { Alert, Box, Popover, Snackbar, Typography } from "@mui/material";
import { useState } from "react";

export const JobErrorDisplay = ({ jobData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  return (jobData.error_type || jobData.error_details) && (jobData.error_type !== null ) ? (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(jobData.error_details);
        setOpenSnackBar(true);
      }}
      style={{ cursor: "pointer" }}
    >
      <Typography
        color="error"
        onMouseEnter={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        onMouseLeave={() => {
          setAnchorEl(null);
        }}
      >
        {jobData.error_type}
      </Typography>
      <Popover
        open={Boolean(anchorEl)}
        sx={{
          pointerEvents: "none",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        disableRestoreFocus
      >
        <Typography
          fontSize="small"
          dangerouslySetInnerHTML={{
            __html: jobData.error_details.replaceAll("\n", "<br/>"),
          }}
        ></Typography>
      </Popover>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Errore copiato
        </Alert>
      </Snackbar>
    </Box>
  ) : (
    <></>
  );
};
